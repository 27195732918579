import PropTypes from 'prop-types'
import { useField } from 'formik'
import { InputLabel, Select, MenuItem, FormHelperText, FormControl } from '@mui/material'
import React from 'react'

function SelectField({ label, data, placeholder, ...props }) {
    const [field, meta] = useField(props)
    const { value: selectedValue } = field

    function _renderHelperText() {
        if (meta.touched && Boolean(meta.error)) {
            return <FormHelperText>{meta.error}</FormHelperText>
        }
    }

    return (
        <FormControl {...props} error={meta.touched && Boolean(meta.error)}>
            <InputLabel>{label}</InputLabel>
            <Select
                placeholder={placeholder}
                variant='outlined'
                inputProps={{ className: 'zi-unset' }}
                {...field}
                value={selectedValue || ''}
            >
                {data.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                        {item.value}
                    </MenuItem>
                ))}
            </Select>
            {_renderHelperText()}
        </FormControl>
    )
}

SelectField.defaultProps = {
    data: [],
}

SelectField.propTypes = {
    label: PropTypes.string,
    data: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
}

export default SelectField
