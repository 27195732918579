import React from 'react'
import { InputField, SelectField } from '../../FormFields'
import localAuthorities from '../../../data/localAuthorities'
import { Grid, Stack, FormHelperText } from '@mui/joy'
import PropTypes from 'prop-types'

PersonalDetails.propTypes = {
    personalDetailsFormField: PropTypes.shape({
        firstName: PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        }),
        lastName: PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        }),
        email: PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        }),
        additionalEmail: PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        }),
        contact: PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        }),
        localAuthority: PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        }),
        postcode: PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        }),
        employerName: PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        }),
        ilsId: PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
            helperText: PropTypes.string,
        }),
    }),
}

export default function PersonalDetails(props) {
    const {
        personalDetailsFormField: {
            firstName,
            lastName,
            email,
            additionalEmail,
            contact,
            localAuthority,
            postcode,
            employerName,
            ilsId,
        },
    } = props

    return (
        <React.Fragment>
            <h2>Your Details</h2>
            <Stack spacing={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <InputField name={firstName.name} label={firstName.label} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name={lastName.name} label={lastName.label} fullWidth />
                    </Grid>
                </Grid>
                <InputField
                    name={email.name}
                    label={email.label}
                    type='email'
                    fullWidth />
                <InputField
                    name={additionalEmail.name}
                    label={additionalEmail.label}
                    type='email'
                    fullWidth />
                <InputField name={contact.name} label={contact.label} fullWidth />
                <h2>Employer Details</h2>
                <InputField name={employerName.name} label={employerName.label} fullWidth />
                <InputField name={ilsId.name} label={ilsId.label} fullWidth required />
                <FormHelperText style={{ paddingBottom: 30, marginTop: 5 }}>
                    {ilsId.helperText}
                </FormHelperText>
                Local Authority
                <SelectField name={localAuthority.name} data={localAuthorities} fullWidth />
                <InputField name={postcode.name} label={postcode.label} fullWidth />

            </Stack>
        </React.Fragment>
    )
}
