import React from 'react'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/joy/LinearProgress'
import PropTypes from 'prop-types'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import {Button} from "@mui/joy";

StepsButton.propTypes = {
    isSubmitting: PropTypes.bool,
    isLastStep: PropTypes.bool,
    handleBack: PropTypes.func,
    activeStep: PropTypes.number,
}

export default function StepsButton(props) {
    const { isSubmitting, isLastStep, handleBack, activeStep } = props

    const { trackEvent } = useMatomo()

    function scrollToTop() {
        window.scrollTo(0, 0)
        trackEvent({
            category: 'Action',
            action: 'Clicked',
            name: 'Next',
        })
    }

    return (
        <>
            <Grid container spacing={2} mt={3} mb={1}>
                <Grid item xs={6}>
                    {activeStep !== 0 && (
                        <Button
                            onClick={handleBack}
                            variant={'outlined'}
                            color={'danger'}
                            sx={{py: 3}}
                            fullWidth>
                            Back
                        </Button>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <Button
                        disabled={isSubmitting}
                        type='submit'
                        color={'danger'}
                        sx={{py: 3}}
                        fullWidth
                        onClick={scrollToTop}
                    >
                        {isLastStep ? 'Submit form' : 'Next'}
                    </Button>
                </Grid>
            </Grid>
            <div>{isSubmitting && <LinearProgress color='danger' />}</div>
        </>
    )
}
