const localAuthorities = [
    {
        value: 'Birmingham',
    },
    {
        value: 'Brighton',
    },
    {
        value: 'Bristol',
    },
    {
        value: 'Bromley',
    },
    {
        value: 'Cambridgeshire',
    },
    {
        value: 'Camden',
    },
    {
        value: 'Cheshire East',
    },
    {
        value: 'Dudley',
    },
    {
        value: 'East Sussex',
    },
    {
        value: 'Lambeth',
    },
    {
        value: 'Lincolnshire',
    },
    {
        value: 'Merthyr',
    },
    {
        value: 'Neath And Port Talbot',
    },
    {
        value: 'Newcastle',
    },
    {
        value: 'North West London',
    },
    {
        value: 'Nottingham City Council',
    },
    {
        value: 'Nottingham County',
    },
    {
        value: 'Redcar And Cleveland',
    },
    {
        value: 'Rotherham',
    },
    {
        value: 'Sheffield',
    },
    {
        value: 'Solihull',
    },
    {
        value: 'South Gloucestershire',
    },
    {
        value: 'Southwark',
    },
    {
        value: 'Stockport',
    },
    {
        value: 'Suffolk',
    },
    {
        value: 'Tower Hamlets',
    },
    {
        value: 'Wakefield',
    },
]

export default localAuthorities
