import * as React from 'react'
import CookieConsent from 'react-cookie-consent'
import './cookies.css'
import { Typography } from '@mui/joy'
import LockOpenIcon from '@mui/icons-material/LockOpen'

export default function Cookies() {

    const [showBanner, setShowBanner] = React.useState(true);

    return (
        <>
            {showBanner && (
            <div >
                <CookieConsent
                    disableStyles
                    location='bottom'
                    buttonText='Accept Essential Cookies'
                    ariaAcceptLabel='Accept Essential Cookies'
                    cookieName='ils_cookies'
                    buttonClasses='cookies-btn'
                    containerClasses='cookies-banner'
                    buttonWrapperClasses='cookies-wrapper'
                >
                    <div>
                        <Typography
                            startDecorator={<LockOpenIcon />}
                            level="h6"
                            component="h1"
                            mb={2}
                            aria-level={2}
                            className={'cookies-title'}
                        >
                            We value your privacy
                        </Typography>
                        <h3>This site uses cookies to store information on your computer.</h3>
                        <h3>
                            We use cookies to help give you the best experience on our site and to
                            allow us and third parties to customise the marketing content you see
                            across websites and social media. For more information, see our {' '}
                            <a href='/policies/cookies-policy' style={{textDecoration: 'none', color:'#b11116'}}>
                                Cookie Policy
                            </a>
                            .
                        </h3>

                        <button
                            style={{
                                float: "left",
                                background: "white",
                                padding: '12px',
                                marginBottom: 10,
                                marginTop: 10,
                                fontWeight: "bolder",
                                borderRadius: "30px",
                                boxShadow: "none",
                                fontSize: 'large',
                                cursor: "pointer",
                                width: 300,
                                marginRight: 10,
                            }}
                            onClick={() => {
                                setShowBanner(false);
                            }}
                        >
                            Accept All Cookies
                        </button>
                    </div>
                </CookieConsent>
            </div>
            )}
        </>
    )
}
