import React from 'react'
import {Field, useFormikContext} from 'formik';
import ReviewPersonalDetails from './ReviewPersonalDetails'
import PropTypes from 'prop-types'
import {Card, Divider, Link} from "@mui/joy";
import {Grid} from "@mui/material";

export default function ReviewSubmission(props) {
    const { title, reviewContent } = props
    const { values: formValues } = useFormikContext()
    const {
        queriesFormField: { form, acceptTerms },
    } = props

    return (
        <React.Fragment>
            <h2>Review Submission</h2>
            <ReviewPersonalDetails
                title={title}
                reviewContent={reviewContent}
                formValues={formValues}
            />

            <Divider sx={{my: 3}} />

            <Card className={'radio-card'}>
                <label>
                    <Grid container style={{cursor: "pointer", padding: '10px 20px 10px 10px '}}>
                        <Field
                            type='radio'
                            name={form.name}
                            label={acceptTerms.label}
                            value={props.title}
                            className={'checked'}
                            required={true} />
                        <Grid item pl={5}>
                            <span> {acceptTerms.label} </span>
                        </Grid>
                    </Grid>
                </label>
                <div style={{ marginTop: 5 }}>
                    <Link
                        href='https://ppl.plus/Policy'
                        target='_blank'
                        sx={{ fontSize: 'small', marginLeft: '50px'}}
                        underline='hover'
                    >
                        Visit our privacy policy.
                    </Link>
                </div>
            </Card>

        </React.Fragment>
    )
}

ReviewSubmission.propTypes = {
    title: PropTypes.string,
    reviewContent: PropTypes.element,
    queriesFormField: PropTypes.shape({
        form: PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
            title: PropTypes.string,
        }),
        acceptTerms: PropTypes.shape({
            label: PropTypes.string,
        }),
    }),
}
