import * as React from 'react'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRightRounded'
import InputFormTitle from '../FormFields/InputFormTitle'
import {
    Button,
    Card,
    Divider,
    Sheet,
    List,
    ListItem,
    ListItemContent,
    ListItemDecorator,
    ListItemButton,
} from '@mui/joy'
import ReceiptIcon from '@mui/icons-material/Receipt'
import FlightIcon from '@mui/icons-material/Flight'
import DriveEtaIcon from '@mui/icons-material/DriveEta'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ContactPageIcon from '@mui/icons-material/ContactPage'
import WifiOffIcon from '@mui/icons-material/WifiOff'
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound'
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useNavigate } from 'react-router-dom'

export default function PayrollQueryMenu() {
    const navigate = useNavigate()
    const categories = [
        {
            title: 'Non Payment',
            icon: <HighlightOffIcon />,
        },
        {
            title: 'Payment Discrepancies',
            icon: <ReceiptIcon />,
        },
        {
            title: 'Holiday Queries ',
            icon: <FlightIcon />,
        },
        {
            title: 'Mileage and Expenses',
            icon: <DriveEtaIcon />,
        },
        {
            title: 'Redundancy',
            icon: <MoreVertIcon />,
        },
    ]

    const other = [
        {
            title: 'Request Sick Pay',
            icon: <LocalHospitalIcon />,
            path: () => navigate(`/payroll/sickpay`),
        },
        {
            title: 'Request Maternity/Paternity Pay',
            icon: <ChildFriendlyIcon />,
            path: () => navigate(`/payroll/maternity-paternity`),
        },
    ]
    const redirect = [
        {
            title: 'Change of contact information or bank details',
            icon: <ContactPageIcon />,
        },
        {
            title: 'Service Closure',
            icon: <WifiOffIcon />,
        },
        {
            title: 'Transfer of Payroll',
            icon: <CurrencyPoundIcon />,
        },
    ]
    return (
        <>
            <Card className={'pp-card'}>
                <InputFormTitle
                    title={'Payroll Query'}
                    subtitle={`raise a payroll query`}
                    tooltip={'This is a form to submit the Payroll Query'}
                />
                <Sheet variant='soft' sx={{ p: 2, borderRadius: '20px', mt: 2 }}>
                    <h2>General Payroll Queries</h2>
                    <List>
                        <Card sx={{ backgroundColor: 'white', p: 1, mx: 2 }}>
                            {categories.map((category, index) => (
                                <ListItem key={index}>
                                    <ListItemDecorator>{category.icon}</ListItemDecorator>

                                    <ListItemContent>
                                        <h3>{category.title}</h3>
                                    </ListItemContent>
                                </ListItem>
                            ))}
                            <Button
                                color={'danger'}
                                size={'lg'}
                                onClick={() => navigate(`/payroll/query`)}
                            >
                                Start
                            </Button>
                        </Card>

                        <Divider sx={{ mt: 3 }} />
                        <h2>Sick Pay and Maternity/Paternity Pay Queries</h2>

                        {other.map((list, index) => (
                            <ListItem key={index}>
                                <List sx={{ backgroundColor: 'white', p: 1, width: '100%' }}>
                                    <ListItem>
                                        <ListItemButton
                                            onClick={list.path}
                                            sx={{ borderRadius: 3 }}
                                        >
                                            <ListItemDecorator>{list.icon}</ListItemDecorator>

                                            <ListItemContent>
                                                <h3>{list.title}</h3>
                                            </ListItemContent>

                                            <KeyboardArrowRight fontSize='xl3' />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </ListItem>
                        ))}

                        <Divider sx={{ mt: 3 }} />
                        <h3>More Payroll Enquiries</h3>
                        <p>
                            You will be redirected to your local advisor section for the queries
                            below.
                        </p>

                        {redirect.map((link, index) => (
                            <ListItem key={index}>
                                <List sx={{ backgroundColor: 'white', p: 1, width: '100%' }}>
                                    <ListItem>
                                        <ListItemButton
                                            onClick={() => navigate(`/local-advisor`)}
                                            sx={{ borderRadius: 3 }}
                                        >
                                            <ListItemDecorator>{link.icon}</ListItemDecorator>

                                            <ListItemContent>
                                                <h3>{link.title}</h3>
                                            </ListItemContent>

                                            <KeyboardArrowRight fontSize='xl3' />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </ListItem>
                        ))}
                    </List>
                </Sheet>
            </Card>
        </>
    )
}
