import React from 'react'
import { Grid } from '@mui/material'
import { InputMessage } from '../../FormFields'
import { RadioGroup } from '@mui/joy'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import UploadField from '../../FormFields/UploadField'
import RadioField from '../../FormFields/RadioField'

PayrollPage.propTypes = {
    queriesFormField: PropTypes.shape({
        query: PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        }),
        payrollType: PropTypes.shape({
            name: PropTypes.string,
        }),
    }),
    values: PropTypes.shape({
        files: PropTypes.array,
    }),
    errors: PropTypes.object,
    setFieldValue: PropTypes.func,
}

export default function PayrollPage(props) {
    const {
        queriesFormField: { query },
    } = props

    const { values, setFieldValue } = props

    const payrollTypes = [
        'Non Payment',
        'Payment Discrepancies',
        'Holiday Queries',
        'Mileage and Expenses',
        'Redundancy',
    ]

    return (
        <React.Fragment>
            <h2>General payroll queries</h2>
            <h3>Select the query related to this submission:</h3>
            <RadioGroup id='payrollType' value={payrollTypes}>
                {payrollTypes.map((payrollType, index) => (
                    <Field
                        component={RadioField}
                        name='payrollType'
                        id={payrollType}
                        label={payrollType}
                        key={index}
                        required
                    />
                ))}
            </RadioGroup>

            <h2>Your Queries</h2>
            <Grid item xs={12}>
                <InputMessage name={query.name} label={query.label} required fullWidth />
            </Grid>
            <h2>
                Upload supporting documents
                <span style={{ fontSize: 'medium' }}> (Optional)</span>
            </h2>
            <UploadField setFieldValue={setFieldValue} values={values} />
        </React.Fragment>
    )
}
