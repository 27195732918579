import React from 'react'
import { Card } from '@mui/material'
import DynamicMenu from '../Home/DynamicMenu'
import InputFormTitle from '../FormFields/InputFormTitle'
import { useNavigate } from 'react-router-dom'
import {Grid} from "@mui/joy";

function DocumentRequestMenu() {
    const navigate = useNavigate()
    const documents = [
        {
            id: 'payslip_copy',
            title: 'Request Copy of Payslip',
            icon: '/icons/payslip02.svg',
            onClick: () => navigate('/document-request/payslip'),
        },
        {
            id: 'p45',
            title: 'Request P45',
            icon: '/icons/p45.svg',
            onClick: () => navigate('/document-request/p45'),
        },
        {
            id: 'p60',
            title: 'Request P60',
            icon: '/icons/p60.svg',
            onClick: () => navigate('/document-request/p60'),
        },
    ]

    return (
        <Card className={'pp-card'}>
            <InputFormTitle
                title={'Document Request'}
                tooltip={'This is a form to Request a Document'}
                subtitle={`request a document`}
            />
            <h3>Select document type</h3>
            <Grid container spacing={2}
                  sx={{paddingBottom:3}}
                  role="navigation"
                  aria-label="Document Request Menu"
                  alignItems="stretch"
            >
                {documents.map((document) => (
                    <Grid item xs={6} sm={4} style={{ height: '280px' }}
                          key={document.id}
                    >
                        <DynamicMenu {...document} />
                    </Grid>
                ))}
            </Grid>
        </Card>
    )
}

export default DocumentRequestMenu
