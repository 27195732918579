import React from 'react'
import PropTypes from "prop-types";
import {Typography} from "@mui/joy";
import Grid from "@mui/material/Grid";

ReviewHours.propTypes = {
    title: PropTypes.string,
    total: PropTypes.number,
    unit: PropTypes.number,
    rate: PropTypes.number,
    note: PropTypes.number,
    extra: PropTypes.bool,
    miles: PropTypes.bool,
    expenses: PropTypes.bool,
}

export default function ReviewHours(props) {
    return(
        <div className={'tb'}>
            <Grid container>
                <Grid item xs={12} sm={2} pt={1}>
                    {props.title}
                </Grid>
                <Grid item xs={12} sm={5}>
                    {props.extra &&
                    <Typography  py={1}>
                        <Typography variant="outlined" color="success">
                            {props.unit}
                        </Typography>{' '} unit x {' '}
                        <Typography variant="outlined" color="primary">
                            &pound;{props.rate}
                        </Typography>{' '} rate

                        {props.note && <p style={{marginBottom: 0}}>{props.note}</p>}
                    </Typography>
                    }
                </Grid>
                <Grid item xs={12} sm={2} className={'cell'}>
                    {props.miles && <>{props.total} miles</>}
                    {props.expenses && <>
                        &pound;{typeof props.total === 'number' ? props.total.toFixed(2) :
                        parseFloat(props.total).toFixed(2)}</>}
                    {!props.extra && !props.miles && !props.expenses && <>{props.total} hours</>}
                    {props.extra && <span className={'cell'}>&pound;{(props.unit * props.rate).toFixed(2)}</span>}
                </Grid>
            </Grid>
        </div>
    )
}
