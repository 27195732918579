import React from 'react'
import { InputField } from '../../../FormFields'
import { Grid } from '@mui/joy'
import Stack from '@mui/material/Stack'
import SectionTitle from '../../../FormFields/SectionTitle'
import DatePicker from '../../../FormFields/DatePicker'
import PropTypes from 'prop-types'

PaDetails.propTypes = {
    index: PropTypes.number,
}

function PaDetails(props) {
    return (
        <>
            <Stack spacing={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            label={`PA's First Name`}
                            name={`timesheet[${props.index}].pa_first_name`}
                            fullWidth
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            label={`PA's Last Name`}
                            name={`timesheet[${props.index}].pa_last_name`}
                            fullWidth
                            required={true}
                        />
                    </Grid>
                </Grid>
                <InputField
                    label={`PA's Email (Optional)`}
                    name={`timesheet[${props.index}].pa_email`}
                    fullWidth
                    type='email'
                />
                <SectionTitle title={'Dates Worked'} />
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <DatePicker
                            name={`timesheet[${props.index}].dateFrom`}
                            label={'Worked from'}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DatePicker
                            name={`timesheet[${props.index}].dateTo`}
                            label={'Worked To'}
                            required={true}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </>
    )
}

export default PaDetails
