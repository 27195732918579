import React, { useState } from 'react'
import {Formik, Form } from 'formik';
import { Stepper, Step, StepLabel } from '@mui/material'
import personalDetailsFormModel from '../../PersonalDetails/FormModel/personalDetailsFormModel'
import PersonalDetails from '../../PersonalDetails/PersonalDetails'
import NotFound from '../../NotFound'
import SubmitSuccess from '../../../SubmitSuccess'
import StepsButton from '../../../Buttons/StepsButton'
import TimesheetReview from '../ReviewPage/TimesheetReview'
import InputFormTitle from '../../../FormFields/InputFormTitle'
import LinearProgress from '@mui/joy/LinearProgress'
import TimesheetPage from '../TimesheetPage'
import SuccessRedirect from '../../../SubmitSuccess/SuccessRedirect'
import usePostForm from '../../../../Hooks/usePostForm'
import queriesFormModel from "../../GeneralQuery/FormModel/queriesFormModel";
import PayslipPage from "../../Payslip/PayslipPage";
import PropTypes from "prop-types";
import ReviewSubmission from "../../GeneralQuery/ReviewSubmission/ReviewSubmission";
import PayslipReview from "../../Payslip/PayslipReview";
import PayrollPage from "../../Payroll/PayrollPage";
import PayrollReview from "../../Payroll/PayrollReview";
import QueryAttachPage from "../../QueryAttach/QueryAttachPage";
import QueryAttachReview from "../../QueryAttach/QueryAttachReview";
import generalQuerySchema from "../../GeneralQuery/FormModel/generalQuerySchema";
import payrollSchema from "../../Payroll/FormModel/payrollSchema";
import MyEpay from "../../MyEpay/MyePay";
import MyEpayReview from "../../MyEpay/MyEpayReview";
import RoleField from "../../../FormFields/RoleField";
import InvoiceReview from "../../Invoice/InvoiceReview";
import InvoicePage from "../../Invoice/InvoicePage";

AddTimesheet.propTypes = {
    timesheet: PropTypes.bool,
    payslip: PropTypes.bool,
    p60: PropTypes.bool,
    p45: PropTypes.bool,
    initialValues: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    tooltip: PropTypes.string,
    payroll: PropTypes.bool,
    invoice: PropTypes.bool,
    complaints: PropTypes.bool,
    myepay: PropTypes.bool,
    attachment: PropTypes.bool,
    validationSchema: PropTypes.array,
}

export default function AddTimesheet(props) {
    const steps = ['Your Details', 'Your Query', 'Review']
    const { personalDetailsFormField } = personalDetailsFormModel
    const [activeStep, setActiveStep] = useState(0)
    const isLastStep = activeStep === steps.length - 1
    const { state, postForm } = usePostForm(process.env.REACT_APP_SUBMIT)
    const { queriesFormField } = queriesFormModel

    function _handleSubmit(values, actions) {
        if (isLastStep) {
            postForm(values).then(() => {
                actions.setSubmitting(false)
                setActiveStep(activeStep + 1)
            })
        } else {
            setActiveStep(activeStep + 1)
            actions.setTouched({})
            actions.setSubmitting(false)
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1)
    }

    return (
        <div className={'pp-card'}>
            <InputFormTitle
                title={props.title}
                tooltip={`This is a form to ${props.title}`}
                subtitle={`submit a ${props.subtitle}`}
            />
            <React.Fragment>
                <Stepper activeStep={activeStep} sx={{ my: 5 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <React.Fragment>
                    {state !== null ? (
                        <>
                            <SubmitSuccess success={state} />
                            <SuccessRedirect />
                        </>
                    ) : (
                        <Formik
                            initialValues={props.initialValues}
                            validationSchema={props.payroll ? payrollSchema[activeStep] : generalQuerySchema[activeStep]}
                            onSubmit={_handleSubmit}
                        >
                            {({ values,
                                  isSubmitting,
                                  setFieldValue,
                                  errors,
                            }) => (
                                <Form id='timesheetForm'>
                                    {isSubmitting && <LinearProgress color='danger' />}
                                    {(() => {
                                        switch (activeStep) {
                                            case 0:
                                                return (
                                                    <div style={{padding: '0 10px'}}>
                                                        <PersonalDetails
                                                            personalDetailsFormField={personalDetailsFormField}/>
                                                    </div>

                                                )
                                            case 1:
                                                return (
                                                    <>
                                                    {props.timesheet && <>
                                                        <TimesheetPage values={values}
                                                                       setFieldValue={setFieldValue} />
                                                    </>}

                                                        {props.payroll && <>
                                                            <PayrollPage
                                                                values={values}
                                                                setFieldValue={setFieldValue}
                                                                queriesFormField={queriesFormField}
                                                                errors={errors}
                                                            />
                                                        </>}

                                                        {props.invoice && <>
                                                            <InvoicePage
                                                                values={values}
                                                                setFieldValue={setFieldValue}
                                                                queriesFormField={queriesFormField}
                                                                errors={errors}
                                                            />
                                                        </>}

                                                        {props.myepay && <>
                                                            <MyEpay
                                                                values={values}
                                                                setFieldValue={setFieldValue}
                                                                queriesFormField={queriesFormField}
                                                                errors={errors}
                                                            />
                                                        </>}

                                                        {props.complaints &&
                                                        <>
                                                            <h2>Submit Compliments and Complaints as</h2>
                                                            <RoleField />
                                                        </>}

                                                        {props.attachment && <>
                                                            <QueryAttachPage
                                                                values={values}
                                                                setFieldValue={setFieldValue}
                                                                queriesFormField={queriesFormField}
                                                             title={props.title}/>
                                                        </>}
                                                        {props.payslip && <PayslipPage
                                                            values={values} title={'payslip'} />}
                                                        {props.p60 && <PayslipPage
                                                            values={values} title={'P60'} />}
                                                        {props.p45 && <PayslipPage
                                                            values={values} title={'P45'} p45={true} />}
                                                    </>
                                                )
                                            case 2:
                                                return (
                                                    <>
                                                    {props.timesheet &&
                                                    <TimesheetReview values={values}
                                                                     queriesFormField={queriesFormField} />
                                                    }

                                                        {props.payroll &&
                                                        <ReviewSubmission
                                                            title={'General Payroll Query'}
                                                            queriesFormField={queriesFormField}
                                                            reviewContent={<PayrollReview
                                                                title={'payroll'} values={values} />}/>
                                                        }

                                                        {props.invoice &&
                                                        <ReviewSubmission
                                                            title={'Invoice and Managed Account Query'}
                                                            queriesFormField={queriesFormField}
                                                            reviewContent={<InvoiceReview
                                                                title={'invoice'} values={values} />}/>
                                                        }

                                                        {props.myepay &&
                                                        <ReviewSubmission
                                                            title={'MyePay Registration Request'}
                                                            queriesFormField={queriesFormField}
                                                            reviewContent={<MyEpayReview
                                                                title={'payroll'} values={values} />}/>
                                                        }

                                                    {props.payslip &&
                                                        <ReviewSubmission
                                                            title={'Payslip Request'}
                                                            queriesFormField={queriesFormField}
                                                            reviewContent={<PayslipReview
                                                                title={'payslip'} values={values} />}/>
                                                    }
                                                    {props.p60 &&
                                                        <ReviewSubmission
                                                            title={'P60 Request'}
                                                            queriesFormField={queriesFormField}
                                                            reviewContent={<PayslipReview
                                                                title={'P60'} values={values} />}/>
                                                    }
                                                    {props.p45 &&
                                                        <ReviewSubmission
                                                            title={'P45 Request'}
                                                            queriesFormField={queriesFormField}
                                                            reviewContent={<PayslipReview
                                                                title={'P45'} values={values} />}/>
                                                    }
                                                    {props.attachment &&
                                                        <ReviewSubmission
                                                            title={props.title}
                                                            queriesFormField={queriesFormField}
                                                            reviewContent={<QueryAttachReview />}/>
                                                    }
                                                    </>
                                                    )
                                            default:
                                                return <NotFound />
                                        }
                                    })()}
                                    <StepsButton
                                        isSubmitting={isSubmitting}
                                        isLastStep={isLastStep}
                                        handleBack={_handleBack}
                                        activeStep={activeStep}
                                    />
                                </Form>
                            )}
                        </Formik>
                    )}
                </React.Fragment>
            </React.Fragment>
        </div>
    )
}
