import React, { useState } from 'react'
import { InputMessage } from '../../../FormFields'
import SectionTitle from '../../../FormFields/SectionTitle'
import { Field } from 'formik'
import RadioField from '../../../FormFields/RadioField'
import { Box, Grid, RadioGroup } from '@mui/joy'
import NightShelterIcon from '@mui/icons-material/NightShelter'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import DatePicker from '../../../FormFields/DatePicker'
import PropTypes from 'prop-types'

PaFooter.propTypes = {
    index: PropTypes.number,
}

function PaFooter(props) {
    const values = props
    const [required, setRequired] = useState(false)
    const [leftDateVisible, setLeftDateVisible] = useState(false)
    const showDateLeft = () => {
        setLeftDateVisible(true)
        setRequired(true)
    }
    const hideDateLeft = () => {
        setLeftDateVisible(false)
        setRequired(false)
    }

    const employmentStatus = [
        {
            id: 'Still employed',
            label: 'The Personal Assistant is still employed',
            onClick: hideDateLeft,
            icon: <NightShelterIcon />,
        },
        {
            id: 'No longer employed',
            label: 'The Personal Assistant is no longer employed',
            onClick: showDateLeft,
            icon: <QueryBuilderIcon />,
        },
    ]
    return (
        <>
            <SectionTitle
                title={'Employment Status'}
                body={'Is the Personal Assistant still employed?'}
            />
            <RadioGroup id='employment_status' value={values.methods} sx={{ mb:1}}>
                {employmentStatus.map((status, index) => (
                    <Field
                        component={RadioField}
                        name={`timesheet[${props.index}].employment_status`}
                        id={status.id}
                        label={status.label}
                        key={index}
                        onClick={status.onClick}
                        required={true}
                    />
                ))}
            </RadioGroup>
            <Grid container>
                <Grid xs={12} sm={6}>
                    {leftDateVisible && (
                        <DatePicker
                            name={`timesheet[${props.index}].date_left`}
                            label={'Date Left'}
                            placeholder={'Select Date'}
                            required={required}
                        />
                    )}
                </Grid>
            </Grid>

            <SectionTitle
                title={'Additional Notes'}
                applicable={true}
                body={
                    'You can use the Additional Notes below to detail anything you think is relevant to this timesheet. This could include any changes to the in rates of pay for this PA.'
                }
            />
            <InputMessage
                name={`timesheet[${props.index}].note`}
                label={'Additional Notes related to this timesheet'}
                fullWidth
            />
            <Box className={'footer-box'}>
                For any queries that are not related to this timesheet, please raise them separately{' '}
                <a href={'/'} target={'_blank'} rel='noreferrer'>
                    here
                </a>
                .
            </Box>
        </>
    )
}

export default PaFooter
