import React from 'react'
import { Grid } from '@mui/joy'
import { InputField } from '../../../FormFields'
import SectionTitle from '../../../FormFields/SectionTitle'
import PropTypes from 'prop-types'

PaExpenses.propTypes = {
    index: PropTypes.number,
}

function PaExpenses(props) {
    const expensesData = [
        {
            name: `timesheet[${props.index}].mileage_expenses`,
            title: 'Mileage Expenses',
            description: 'The number of miles to be paid at agreed rate',
            placeholder: '0',
            rate: false,
        },
        {
            name: `timesheet[${props.index}].other_expenses`,
            title: 'Other Expenses',
            description: 'Expense incurred not related to mileage',
            placeholder: '0.00',
            rate: true,
        },
    ]
    return (
        <>
            <SectionTitle title={'Expenses'} applicable={true} />
            <Grid container className={'tab-header'} spacing={1}>
                {expensesData.map((expense, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <div className={'hour-card'}>
                            <h3 style={{ margin: 0 }}>{expense.title}</h3>
                            <div className={'hr-desc'}>{expense.description}</div>
                            <Grid container>
                                {expense.rate && (
                                    <Grid item xs={1} sx={{ pr: 3 }}>
                                        <div className={'price'}>&pound;</div>
                                    </Grid>
                                )}

                                <Grid item xs={10}>
                                    <InputField
                                        variant='standard'
                                        InputProps={{ className: 'hr-log' }}
                                        name={expense.name}
                                        placeholder={expense.placeholder}
                                        type={'number'}
                                        step="0.01"
                                        min={'0'}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default PaExpenses
