import React from 'react'
import {Box, Card, Stack, Typography} from '@mui/joy';
import { useFormikContext } from 'formik'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

export default function QueryAttachReview() {
    const { values: formValues } = useFormikContext()
    const { files, roles } = formValues
    return (
        <React.Fragment>
            <Stack sx={{mt:3}}>
                {roles &&
                <Typography sx={{mt: 2}} startDecorator={<PersonOutlineIcon className={'gray-bg'}/>}>
                    Submit as: {roles}
                </Typography>
                }

               {files && files.length>0 && <h4>Attached documents</h4>}
                {files &&
                files.map((file, i) => (
                    <div key={i}>
                    <Card row variant='outlined' sx={{mb: 2}}>
                        <Box sx={{display: 'flex', gap: 1.5, mt: 'auto'}}>
                            <AttachFileIcon
                                className={'gray-bg'}
                                sx={{width: 30, fontSize: 30, ml: 2}}
                            />
                            <div>
                                <Typography fontWeight='md' color='danger' mb={0.5}>
                                    {file.name}</Typography>
                                <Typography fontWeight="lg" level="body2">
                                    {file.size} bytes
                                </Typography>
                            </div>
                        </Box>
                    </Card>
                </div>
                ))}
            </Stack>
        </React.Fragment>
    )
}
