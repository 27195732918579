import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import { Radio } from '@mui/joy'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import React from 'react'
import PropTypes from 'prop-types'

const RadioField = ({
    field: { name, value, onChange, onBlur },
    id,
    label,
    className,
    icon,
    onClick,
    ...props
}) => {
    return (
        <List
            sx={{
                minWidth: 240,
                '--List-gap': '0.5rem',
                '--List-item-paddingY': '1rem',
                '--List-item-radius': '8px',
                '--List-decorator-size': '32px',
            }}
        >
            <ListItem variant='outlined'>
                <Radio
                    overlay
                    label={label}
                    name={name}
                    id={id}
                    value={id}
                    sx={{ flexGrow: 1 }}
                    checked={id === value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onClick={onClick}
                    componentsProps={{
                        action: ({ checked }) => ({
                            sx: (theme) => ({
                                ...(checked && {
                                    inset: -1,
                                    border: '2px solid',
                                    borderColor: theme.vars.palette.primary[500],
                                }),
                            }),
                        }),
                    }}
                    {...props}
                />
                <ListItemDecorator>
                    <>{icon}</>
                </ListItemDecorator>
            </ListItem>
        </List>
    )
}

RadioField.propTypes = {
    field: PropTypes.object,
    id: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.object,
    onClick: PropTypes.func,
}

export default RadioField
