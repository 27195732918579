import personalDetailsFormModel from '../../PersonalDetails/FormModel/personalDetailsFormModel'
import queriesFormModel from '../../GeneralQuery/FormModel/queriesFormModel'

const {
    personalDetailsFormField: {
        firstName,
        lastName,
        email,
        additionalEmail,
        contact,
        localAuthority,
        postcode,
        employerName,
        ilsId,
    },
} = personalDetailsFormModel

const {
    queriesFormField: { query, files, form },
} = queriesFormModel

const queryAttachInitialValues = {
    [form.name]: '',
    [firstName.name]: '',
    [lastName.name]: '',
    [email.name]: '',
    [additionalEmail.name]: '',
    [contact.name]: '',
    [employerName.name]: '',
    [ilsId.name]: '',
    [localAuthority.name]: '',
    [postcode.name]: '',
    [query.name]: '',
    [files.name]: [],
    // [files.name]: null,
}

export default queryAttachInitialValues
