import personalDetailsFormModel from "../PersonalDetails/FormModel/personalDetailsFormModel";
import queriesFormModel from "../GeneralQuery/FormModel/queriesFormModel";

const {
    personalDetailsFormField: {
        firstName,
        lastName,
        email,
        contact,
        localAuthority,
        postcode,
        employerName,
        ilsId,
    },
} = personalDetailsFormModel

const {
    queriesFormField: { query, form, files },
} = queriesFormModel

const payrollInitialValues = {
    [form.name]: 'Invoice',
    [firstName.name]: '',
    [lastName.name]: '',
    [email.name]: '',
    [contact.name]: '',
    [employerName.name]: '',
    [ilsId.name]: '',
    [localAuthority.name]: '',
    [postcode.name]: '',
    [query.name]: '',
    [files.name]: [],
}

export default payrollInitialValues
