import React from 'react'
import Box from '@mui/material/Box'

function NotFound() {
    return (
        <div className={'pp-card'}>
            <Box>
                <h3>Error 404: Page Not Found</h3>
            </Box>
        </div>
    )
}

export default NotFound
