const timesheetFormModel = {
    timesheetFormField: {
        form: {
            name: 'form',
        },
        employerName: {
            name: 'employerName',
            label: `Employer's Name`,
            helperText: `As it is shown on the Payslip if known`,
            requiredErrorMsg: `Employer's name is required`,
        },
        ilsId: {
            name: 'ilsId',
            label: 'ILS Number',
            requiredErrorMsg: `Please provide ILS Number`,
            helperText: `You can find your ILS number on the payment schedule correspondence letter/email. This makes it much easier for PeoplePlus to deal with your query/timesheet as quickly as possible so it is very important we have this information. It contains 3 letters then 6 numbers, e.g. ESU123456.`,
            invalidErrorMsg: `ILS Number must be 9 characters long`,
        },
        pa_first_name: 'pa_first_name',
        pa_last_name: 'pa_last_name',
        pa_email: 'pa_email',
        dateFrom: 'dateFrom',
        dateTo: 'dateTo',
        payment_method: 'payment_method',
        employment_status: 'employment_status',
        hours_basic: 'hours_basic',
        hours_holiday: 'hours_holiday',
        hours_weekend: 'hours_weekend',
        hours_bank_holiday: 'hours_bank_holiday',
        mileage_expenses: 'mileage_expenses',
        other_expenses: 'other_expenses',
        overnight_unit: 'overnight_unit',
        overnight_rate: 'overnight_rate',
        overnight_total: 'overnight_total',
        livein_unit: 'livein_unit',
        livein_rate: 'livein_rate',
        livein_total: 'livein_total',
        respite_unit: 'respite_unit',
        respite_rate: 'respite_rate',
        respite_total: 'respite_total',
        other_unit:  'other_unit',
        other_rate: 'other_rate',
        other_rate_total: 'other_rate_total',
        other_rate_note: 'other_rate_note',
        date_left: 'date_left',
        note: 'note',
        files: {
            name: `files`,
        },
        timesheet: {
            name: `timesheet`,
        },
    },
}

export default timesheetFormModel
