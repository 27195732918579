import React from 'react'
import PropTypes from 'prop-types'

SectionTitle.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    applicable: PropTypes.bool,
}

export default function SectionTitle(props) {
    return (
        <>
            <h2 style={{ marginTop: 40 }}>
                {props.title}
                {props.applicable && <span style={{ fontSize: 'medium' }}> - If applicable</span>}
            </h2>
            {props.body && <p>{props.body}</p>}
        </>
    )
}
