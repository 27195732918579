import React from 'react'
import { RadioGroup } from '@mui/joy'
import { Field } from 'formik'
import RadioField from '../../../FormFields/RadioField'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import SectionTitle from '../../../FormFields/SectionTitle'
import PropTypes from 'prop-types'

PaPayment.propTypes = {
    index: PropTypes.number,
}

function PaPayment(props) {
    const values = props

    const methods = [
        {
            id: 'I pay the Personal Assistant directly',
            icon: <CreditScoreIcon />,
        },
        {
            id: 'PeoplePlus pay my Personal Assistant',
            icon: <SupportAgentIcon />,
        },
        {
            id: 'Personal Assistant is paid by a Prepaid Card',
            icon: <CardMembershipIcon />,
        },
    ]
    return (
        <>
            <SectionTitle title={'Payment Method'} />
            <RadioGroup value={values.methods}>
                {methods.map((method, index) => (
                    <Field
                        component={RadioField}
                        name={`timesheet[${props.index}].payment_method`}
                        id={method.id}
                        label={method.id}
                        icon={method.icon}
                        key={index}
                        required={true}
                    />
                ))}
            </RadioGroup>
        </>
    )
}

export default PaPayment
