import React from 'react'
import { FieldArray } from 'formik'
import Grid from '@mui/material/Grid'
import { Button, Card, Sheet } from '@mui/joy'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import ClearIcon from '@mui/icons-material/Clear'
import { InputField, InputMessage } from '../../FormFields'
import PropTypes from 'prop-types'
import DatePicker from '../../FormFields/DatePicker'

PayslipPage.propTypes = {
    values: PropTypes.shape({
        docRequest: PropTypes.array,
    }),
    index: PropTypes.number,
    title: PropTypes.string,
    p45: PropTypes.bool,
}

export default function PayslipPage(props) {
    function renderDatePicker(name, label) {
        return <DatePicker name={name} label={label} required={true} />
    }

    return (
        <div>
            <Sheet variant='soft' sx={{ p: 2, borderRadius: '20px' }}>
                <h2 style={{ marginTop: 6 }}>List of PAs requiring {props.title} copies</h2>
                <FieldArray
                    values={props.values}
                    name='docRequest'
                    render={(arrayHelpers) => (
                        <div>
                            {props.values.docRequest && props.values.docRequest.length > 0
                                ? props.values.docRequest.map((sheet, index) => (
                                      <div className={'log-card'} key={index}>
                                          <Grid container spacing={1} p={1}>
                                              <Grid item xs={2} sm={1}>
                                                  <Card
                                                      sx={{
                                                          backgroundColor: '#b11116',
                                                          fontWeight: 'bold',
                                                          color: 'white',
                                                      }}
                                                  >
                                                      {`${index + 1}`}
                                                  </Card>
                                              </Grid>
                                              <Grid item sm={5} xs={10}>
                                                  <InputField
                                                      label={`PA's First Name`}
                                                      name={`docRequest[${index}].pa_first_name`}
                                                      fullWidth
                                                      required={true}
                                                  />
                                              </Grid>
                                              <Grid item sm={6} xs={12}>
                                                  <InputField
                                                      label={`PA's Last Name`}
                                                      name={`docRequest[${index}].pa_last_name`}
                                                      fullWidth
                                                      required={true}
                                                  />
                                              </Grid>
                                          </Grid>

                                          <Grid container spacing={1} mt={1} pl={1}>
                                              {!props.p45 && (
                                                  <>
                                                      <Grid item xs={12} sm={6}>
                                                          {renderDatePicker(
                                                              `docRequest[${index}].dateFrom`,
                                                              'Date from',
                                                          )}
                                                      </Grid>
                                                      <Grid item xs={12} sm={6}>
                                                          {renderDatePicker(
                                                              `docRequest[${index}].dateTo`,
                                                              'Date To',
                                                          )}
                                                      </Grid>
                                                  </>
                                              )}
                                              {props.p45 && (
                                                  <Grid item xs={12} sm={6}>
                                                      {renderDatePicker(
                                                          `docRequest[${index}].endDate`,
                                                          'Employment End Date',
                                                      )}
                                                  </Grid>
                                              )}
                                          </Grid>

                                          <Grid container spacing={1} mt={4}>
                                              <Grid item xs={12} sm={6}>
                                                  <Button
                                                      variant={'outlined'}
                                                      startDecorator={<ClearIcon />}
                                                      onClick={() => arrayHelpers.remove(index)}
                                                      sx={{
                                                          py: 2,
                                                          border: '1px solid gray',
                                                          color: 'black',
                                                      }}
                                                      disabled={
                                                          props.values.docRequest.length === 1
                                                      }
                                                  >
                                                      Delete
                                                  </Button>
                                              </Grid>
                                              <Grid item xs={12} sm={6}>
                                                  <Button
                                                      color={'danger'}
                                                      sx={{ py: 2 }}
                                                      startDecorator={<PersonAddAltIcon />}
                                                      onClick={() => arrayHelpers.push('')}
                                                  >
                                                      Add Personal Assistant
                                                  </Button>
                                              </Grid>
                                          </Grid>
                                      </div>
                                  ))
                                : null}
                        </div>
                    )}
                />
            </Sheet>

            <h2 style={{ marginTop: 40 }}>
                Additional comments<span style={{ fontSize: 'medium' }}> (Optional)</span>
            </h2>
            <InputMessage name='payslipComment' label='Additional comments' fullWidth />
        </div>
    )
}
