import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/joy'

function SubmitSuccess({ success }) {
    let title = 'There was an error.'
    let content = 'Your submission failed to submit, please retry.'
    let body = `Contact us if the issue persists.`

    if (success === true) {
        title = 'Your submission has been successful and will now be processed.'
        content = `You will receive an email with the confirmation and reference number for your submission shortly.`
        body = `Thank you.`
    }

    return (
        <React.Fragment>
            <Grid container className={'submit-bg'}>
                <Grid item xs={5} sm={8}>
                    <h1>{title}</h1>
                    <h2>{content}</h2>
                    <h2>{body}</h2>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

SubmitSuccess.propTypes = {
    success: PropTypes.bool,
}

export default SubmitSuccess
