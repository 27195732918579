import React from 'react'
import { useFormikContext } from 'formik'
import { Typography } from '@mui/joy'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import QueryAttachReview from '../QueryAttach/QueryAttachReview'

export default function MyEpayReview() {
    const { values: formValues } = useFormikContext()
    const { dob, employerEmail } = formValues
    const convert = function (match, y, m, d) {
        return d + '/' + m + '/' + y
    }
    return (
        <>
            <Typography sx={{ mt: 2 }} startDecorator={<PersonOutlineIcon className={'gray-bg'} />}>
                Your Email: {employerEmail}
            </Typography>
            <Typography sx={{ mt: 2 }} startDecorator={<PersonOutlineIcon className={'gray-bg'} />}>
                Date of Birth: {dob.replace(/(\d{4})-(\d{1,2})-(\d{1,2})/, convert)}
            </Typography>
            <QueryAttachReview />
        </>
    )
}
