import React from 'react'
import { Card, CardActionArea, CardContent, CardMedia } from '@mui/material'
import PropTypes from 'prop-types'
import { useMatomo } from '@datapunt/matomo-tracker-react'

export default function DynamicMenu({ onClick, icon, title, subtitle= '' }) {
    const { trackEvent } = useMatomo()

    const handleClick = () => {
        trackEvent({
            category: 'Page Navigation',
            action: 'Clicked',
            name: `Go to ${title}`,
        });
        onClick();
    };
    return (
        <button
            type="button"
            onClick={handleClick}
            style={{ height: '100%', border: "none", backgroundColor: 'transparent' }}
        >
            <Card
                data-testid="dynamicMenu"
                style={{
                    height: '100%',
                    boxShadow: '0 0 3px rgba(0, 0, 0, 0.3)',
                    borderRadius: '20px',
                    textAlign: 'center',
                    transition: 'transform 0.3s, border 0.3s',
                }}
                sx={{
                    '&:hover': {
                        transform: 'scale(1.03)',
                        border: '4px solid #b11116',
                    },
                }}
            >
            <CardActionArea style={{ height: '100%'}}>
                <CardMedia component="img" className={'btn-icon'} image={icon} alt="icon" />
                <CardContent style={{ paddingBottom: '5px' }}>
                    <h3 style={{ margin: '10px 0', fontSize: '20px' }}>{title}</h3>
                    {subtitle && <p className={'subtitle'}>{subtitle}</p>}
                </CardContent>
            </CardActionArea>
        </Card>
        </button>
    );
}

DynamicMenu.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    icon: PropTypes.string.isRequired,
};
