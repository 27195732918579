import React from 'react'
import { Sheet, Typography } from '@mui/joy'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import PropTypes from 'prop-types'

export default function PAQuery(props) {
    PAQuery.propTypes = { subtitle: PropTypes.string.isRequired }

    return (
        <Sheet sx={{ p: 2, borderRadius: 3, my: 3, backgroundColor: 'lightgray' }}>
            <Typography startDecorator={<PersonOutlineIcon className={'gray-bg'} />}>
                If you&apos;re a personal assistant and wish to {' ' + props.subtitle}, please
                contact your employer to submit on your behalf.
            </Typography>
        </Sheet>
    )
}
