import React from 'react'
import {Box,Card,Divider,Grid,Link,Stack,Typography} from '@mui/joy';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import DateRangeIcon from '@mui/icons-material/DateRange'
import PaymentIcon from '@mui/icons-material/Payment'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PortraitIcon from '@mui/icons-material/Portrait'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import PhoneIcon from '@mui/icons-material/Phone'
import localAuthorities from '../../../../data/localAuthorities'
import BadgeIcon from '@mui/icons-material/Badge'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import NotesIcon from '@mui/icons-material/Notes'
import PropTypes from 'prop-types'
import {Field} from "formik";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ReviewHours from "./ReviewHours";

TimesheetReview.propTypes = {
    values: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        additionalEmail: PropTypes.string,
        contact: PropTypes.string,
        employerName: PropTypes.string,
        ilsId: PropTypes.string,
        postcode: PropTypes.string,
        localAuthority: PropTypes.string,
        timesheet: PropTypes.array,
        files: PropTypes.array,
    }),
    queriesFormField: PropTypes.shape({
        acceptTerms: PropTypes.shape({
            label: PropTypes.string,
        }),
    }),
}

export default function TimesheetReview(props) {
    const review = props.values
    const {queriesFormField: {acceptTerms}} = props
    const convert = function (match, y, m, d) {
        return d + '/' + m + '/' + y;
    }
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <div>
                        <h2>Your Details</h2>
                        <Grid container sx={{ mb:5}}>
                            <Grid item xs={12} sm={6}>
                                <Stack spacing={3}>
                                    <Typography startDecorator={<PortraitIcon className={'gray-bg'} />}>
                                        Name <br />
                                        {review.firstName} {review.lastName}
                                    </Typography>
                                    <Typography startDecorator={<AlternateEmailIcon className={'gray-bg'} />}>
                                        Email <br /> {review.email}
                                    </Typography>
                                    {review.additionalEmail &&
                                    <Typography startDecorator={<AlternateEmailIcon className={'gray-bg'}/>}>
                                        Additional email <br/> {review.additionalEmail}
                                    </Typography>
                                    }
                                    <Typography startDecorator={<PhoneIcon className={'gray-bg'} />}>
                                        Contact <br /> {review.contact}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} data={localAuthorities}>
                                <h4 className={'empl-copy'}>About Employer</h4>
                                <Stack spacing={3}>
                                    <Typography
                                        sx={{ pt:2}}
                                        startDecorator={<BadgeIcon className={'gray-bg'} />}
                                    >
                                        Name: {review.employerName}
                                        <br />
                                        ID: {review.ilsId}
                                    </Typography>
                                    <Typography startDecorator={<FmdGoodIcon className={'gray-bg'} />}>
                                        Postcode: {review.postcode} <br />
                                        Local Authority: {review.localAuthority}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my:5}} />
                        <h2>Timesheet Submission</h2>
                        <Card variant={'soft'}>
                            { review.timesheet && review.timesheet.map((item,i) => {
                                return (
                                    <Card variant={'outlined'} key={i}>
                                        <h3>
                                            Timesheet for {item.pa_first_name} {item.pa_last_name}
                                        </h3>
                                        <div className={'bold-border'} />
                                        <Stack spacing={3} sx={{ pt:1}}>
                                            <Typography
                                                startDecorator={<PersonOutlineIcon className={'gray-bg'} />}
                                            >
                                                Name: {item.pa_first_name} {item.pa_last_name} <br />
                                                Email: {item.pa_email}
                                            </Typography>

                                            <Typography startDecorator={<DateRangeIcon className={'gray-bg'} />}>
                                                Timesheet for: <br />
                                                <Typography variant='soft' component='strong'>
                                                    {item.dateFrom.replace(/(\d{4})-(\d{1,2})-(\d{1,2})/, convert)} to{' '}
                                                    {item.dateTo.replace(/(\d{4})-(\d{1,2})-(\d{1,2})/, convert)}
                                                </Typography>
                                            </Typography>

                                            <Typography startDecorator={<PaymentIcon className={'gray-bg'} />}>
                                                Payment method: {item.payment_method}
                                            </Typography>

                                            <Typography startDecorator={<WorkOutlineIcon className={'gray-bg'} />}>
                                                Employment Status: {item.employment_status}
                                                <span style={{paddingLeft: 5}}>
                                    {item.date_left && (
                                        <>since  <strong>
                                            {item.date_left.replace(/(\d{4})-(\d{1,2})-(\d{1,2})/, convert)}</strong>
                                        </>)}
                                </span>
                                            </Typography>

                                            <Typography
                                                fontSize='lg'
                                                fontWeight='lg'
                                                startDecorator={<AccessTimeIcon className={'gray-bg'}/>}
                                            >
                                                Hours worked, additional rates and expenses:
                                            </Typography>

                                            <Card variant={'soft'}>
                                                <Stack px={2} >
                                                    {item.hours_basic && (
                                                        <ReviewHours title={'Basic Hours'} total={`${item.hours_basic}`}/>
                                                    )}
                                                    {item.hours_holiday && (
                                                        <ReviewHours title={'Holiday Hours'} total={`${item.hours_holiday}`}/>
                                                    )}
                                                    {item.hours_weekend && (
                                                        <ReviewHours title={'Weekend Hours'} total={`${item.hours_weekend}`}/>
                                                    )}
                                                    {item.hours_bank_holiday && (
                                                        <ReviewHours title={'Bank Holiday Hours'}
                                                                     total={`${item.hours_bank_holiday}`}/>
                                                    )}

                                                    {item.overnight_unit && (
                                                        <ReviewHours title={'Overnight:'}
                                                                     extra={true}
                                                                     unit={`${item.overnight_unit}`}
                                                                     rate={`${item.overnight_rate}`}
                                                        />
                                                    )}

                                                    {item.livein_unit && (
                                                        <ReviewHours title={'Live in:'}
                                                                     extra={true}
                                                                     unit={`${item.livein_unit}`}
                                                                     rate={`${item.livein_rate}`}
                                                        />
                                                    )}

                                                    {item.respite_unit && (
                                                        <ReviewHours title={'Respite care:'}
                                                                     extra={true}
                                                                     unit={`${item.respite_unit}`}
                                                                     rate={`${item.respite_rate}`}
                                                        />
                                                    )}

                                                    {item.other_unit && (
                                                        <>
                                                            <ReviewHours title={'Other:'}
                                                                         extra={true}
                                                                         unit={`${item.other_unit}`}
                                                                         note={`${item.other_rate_note}`}
                                                                         rate={`${item.other_rate}`}
                                                            />
                                                        </>
                                                    )}

                                                    {item.mileage_expenses && (
                                                        <ReviewHours
                                                            miles={true}
                                                            title={'Mileage Expenses'} total={`${item.mileage_expenses}`}/>
                                                    )}

                                                    {item.other_expenses && (
                                                        <ReviewHours
                                                            expenses={true}
                                                            title={'Other Expenses'} total={`${item.other_expenses}`}/>

                                                    )}
                                                </Stack>
                                            </Card>

                                            {item.note && (
                                                <Typography
                                                    sx={{ whiteSpace: 'pre-line',pb:4}}
                                                    startDecorator={<NotesIcon className={'gray-bg'} />}
                                                >
                                                    Additional note: <br />
                                                    <br />
                                                    {item.note}
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Card>
                                )
                            })}
                        </Card>
                        <Stack sx={{ my:2}}>
                            {review.files  && review.files.length > 1 && (
                                <>
                                    <h2>Attachments</h2>
                                </>
                            )}
                            {review.files &&  review.files.map((file,i) => (
                                <div  key={i}>
                                    <Card row variant='outlined' sx={{ mb:2}}>

                                        <Box sx={{ display: 'flex',gap:1.5,mt: 'auto' }}>
                                            <AttachFileIcon
                                                className={'gray-bg'}
                                                sx={{ width:30,fontSize:30,ml:2}}
                                            />
                                            <div>
                                                <Typography fontWeight='md' color='danger' mb={0.5}>
                                                    {file.name}</Typography>
                                                <Typography fontWeight="lg" level="body2">
                                                    {file.size} bytes
                                                </Typography>
                                            </div>
                                        </Box>
                                    </Card>
                                </div>
                            ))}
                        </Stack>
                        <Divider sx={{mb: 4}} />
                        <Card className={'radio-card'}>
                            <label>
                                <Grid container style={{cursor: "pointer",padding: '10px 20px 10px 10px '}}>
                                    <Field
                                        type='radio'
                                        label={acceptTerms.label}
                                        name={'form'}
                                        value={'timesheet'}
                                        className={'checked'}
                                        required={true}
                                    />
                                    <Grid item pl={5}>
                                        <span> {acceptTerms.label} </span>
                                    </Grid>
                                </Grid>
                            </label>
                            <div style={{ marginTop:5}}>
                                <Link
                                    href='https://ppl.plus/Policy'
                                    target='_blank'
                                    sx={{ fontSize: 'small',marginLeft: '50px'}}
                                    underline='hover'
                                >
                                    Visit our privacy policy.
                                </Link>
                            </div>
                        </Card>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
