const personalDetailsFormModel = {
    formId: 'personalDetailsForm',
    personalDetailsFormField: {
        firstName: {
            name: 'firstName',
            label: 'First Name',
            requiredErrorMsg: 'First name is required',
        },
        lastName: {
            name: 'lastName',
            label: 'Last name',
            requiredErrorMsg: 'Last name is required',
        },
        email: {
            name: 'email',
            label: 'Your Email',
            requiredErrorMsg: 'Please provide your email address',
            invalidEmail: 'Please provide a valid email address',
        },
        additionalEmail: {
            name: 'additionalEmail',
            label: 'Additional Email (If needed)',
            requiredErrorMsg: 'Please provide your email address',
            invalidEmail: 'Please provide a valid email address',
        },
        contact: {
            name: 'contact',
            label: 'Contact number',
            requiredErrorMsg: 'Please provide a contact number',
        },
        employerName: {
            name: 'employerName',
            label: 'Employer Name',
            requiredErrorMsg: 'Please provide an employer name',
        },
        ilsId: {
            name: 'ilsId',
            label: 'ILS Number',
            requiredErrorMsg: `Please provide ILS Number`,
            invalidErrorMsg: `ILS Number must be 9 characters long`,
            helperText: `You can find your ILS number on the payment schedule correspondence letter/email. 
            This will allow us to deal with this matter as quickly as possible. It contains 3 letters then 6
             numbers, e.g. ESU123456. If you don't know your ILS number please contact the Service Support Team 
             on 0330 123 2815, who will advise you accordingly.`,
        },
        localAuthority: {
            name: 'localAuthority',
            label: 'Local Authority',
            requiredErrorMsg: 'Please select a local authority',
        },
        postcode: {
            name: 'postcode',
            label: 'Postcode',
            requiredErrorMsg: 'Postcode is required',
            invalidErrorMsg: 'Postcode is not valid (e.g. SE12 7AU)',
        },
    },
}

export default personalDetailsFormModel
