import React from 'react'
import { InputField } from '../../../FormFields'
import Stack from '@mui/material/Stack'
import SectionTitle from '../../../FormFields/SectionTitle'
import {Card, Grid} from '@mui/joy';
import PropTypes from 'prop-types'

PaHours.propTypes = {
    index: PropTypes.number,
}

function PaHours(props) {

    const hoursData = [
        {
            name: `timesheet[${props.index}].hours_basic`,
            title: 'Basic Rate Hours (Weekdays)',
            description: `Total Monday - Friday, Daytime hours worked, to be paid at the 'Basic Rate'`,
            placeholder: '0',
        },
        {
            name: `timesheet[${props.index}].hours_weekend`,
            title: 'Weekend Rate Hours',
            description: `Total Hours worked at the \n'Weekend Rate'`,
            placeholder: '0',
        },
        {
            name: `timesheet[${props.index}].hours_holiday`,
            title: 'Holiday Hours',
            description: 'Statutory Annual Leave',
            placeholder: '0',
        },
        {
            name: `timesheet[${props.index}].hours_bank_holiday`,
            title: 'Bank Holiday Hours',
            description: 'Worked on the bank holiday',
            placeholder: '0',
        },
    ]
    return (
        <>
            <Stack spacing={3}>
                <SectionTitle title={'Hours Worked'} />
                <Card className={'warning-card border'}>
                    <strong>Please do not combine hours.</strong>
                    <p>
                        i.e: If a PA has worked 80 hours in the <span style={{fontWeight: 'bold'}}>weekdays</span>
                        , and 20 hours on the
                        <span style={{fontWeight: 'bold'}}> weekend</span>
                        : Do not enter 100 Basic Rate Hours AND 20 Weekend Hours. This will resulting in 120 hours processed and overpaying the Personal Assistant.
                    </p>
                </Card>

                <Grid container className={'tab-header'} spacing={1} style={{ marginTop: 0 }}>
                    {hoursData.map((hour, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                            <div className={'hour-card'}>
                                <h3 style={{ margin: 0 }}>{hour.title}</h3>
                                <div className={'hr-desc'}>{hour.description}</div>
                                <Grid container>
                                    {hour.rate && (
                                        <Grid item xs={1} sx={{ pr: 3 }}>
                                            <div className={'price'}>&pound;</div>
                                        </Grid>
                                    )}

                                    <Grid item xs={10}>
                                        <InputField
                                            variant='standard'
                                            InputProps={{ className: 'hr-log' }}
                                            name={hour.name}
                                            placeholder={hour.placeholder}
                                            type={'number'}
                                            step="0.01"
                                            min="0"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </>
    )
}

export default PaHours
