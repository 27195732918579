const queriesFormModel = {
    queriesFormField: {
        form: {
            name: 'form',
        },
        query: {
            name: 'query',
            label: 'Enter your query',
            requiredErrorMsg: 'Please enter your query.',
        },
        files: {
            name: 'files',
            label: 'Attach the supporting document or evidence.',
        },
        payment_method: {
            name: 'payment_method',
            label: 'Select a payment method.',
            requiredErrorMsg: 'Please select a payment method.',
        },
        roles: {
            name: 'roles',
            label: `Submit Compliments and Complaints as:`,
        },
        dateFrom: {
            name: 'dateFrom',
        },
        dateTo: {
            name: 'dateTo',
        },
        endDate: {
            name: 'endDate',
        },
        payslipComment: {
            name: 'payslipComment',
        },
        employerEmail: {
            name: 'employerEmail',
        },
        dob: {
            name: 'dob',
        },
        payrollType: {
            name: 'payrollType',
        },
        acceptTerms: {
            name: 'acceptTerms',
            label: `I agree to the terms and conditions and the PeoplePlus Privacy Policy.`,
        },
    },
}

export default queriesFormModel
