import React from 'react'
import DateRangeIcon from '@mui/icons-material/DateRange'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Field } from 'formik';

DatePickerField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
}

function DatePickerField(props) {
    const { label,name } = props

    return (
        <>
            <Grid container>
                <Grid style={{ marginTop:28}}>
                    <DateRangeIcon className={'gray-bg'} />
                </Grid>
                <Grid xs={8} item>
                    <div>
                        <label htmlFor={name}>{label}</label>
                        <Field
                            className={'date-picker'}
                            type="date"
                            placeholder={'DD/MM/YYYY'}
                            required={true}
                            name={name}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default DatePickerField
