import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { Button, Grid } from '@mui/joy'
import HomeIcon from '@mui/icons-material/Home'
import { useNavigate } from 'react-router-dom'
import remarkGfm from 'remark-gfm'
import PropTypes from 'prop-types'

PolicyPage.propTypes = {
    name: PropTypes.string,
}

export default function PolicyPage({ name }) {
    const navigate = useNavigate()
    const [content, setContent] = useState('')

    useEffect(() => {
        const file = `$web/policies/`

        fetch(process.env.REACT_APP_POLICY_BASE_URL + file + name)
            .then((res) => res.text())
            .then((text) => setContent(text))
    }, [name])

    return (
        <div className='pp-card'>
            <Grid container spacing={2}>
                <Grid item xs={1}>
                    <Button onClick={() => navigate('/')} variant='outlined' color='neutral'>
                        <HomeIcon />
                    </Button>
                </Grid>
                <Grid item xs={11}>
                    <h1 style={{ marginTop: 4 }}>Policies</h1>
                </Grid>
            </Grid>

            <div className={'bold-border'} />
            <div style={{ paddingTop: 1 }}>
                <p>
                    In this policy, &lsquo;we&rsquo;, &lsquo;us&rsquo;, &lsquo;PeoplePlus&rsquo;,
                    and &lsquo;Independent Living Services (ILS)&rsquo; means:
                </p>

                <p>
                    PeoplePlus Group Limited. 19-20 The Triangle, Ng2 Business Park, Nottingham, NG2
                    1AE.
                </p>

                <p>
                    PeoplePlus Group Limited is the owner of this Website and the PeoplePlus
                    trademark and brand, and &lsquo;you&rsquo; means the user of this Website. We
                    reserve the right to withdraw all or part of the Website at any time.
                </p>
            </div>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
        </div>
    )
}
