import React from 'react'
import { Typography, Grid, Divider, Stack } from '@mui/joy'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import PhoneIcon from '@mui/icons-material/Phone'
import PortraitIcon from '@mui/icons-material/Portrait'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import localAuthorities from '../../../../data/localAuthorities'
import BadgeIcon from '@mui/icons-material/Badge'
import PropTypes from 'prop-types'

ReviewPersonalDetails.propTypes = {
    formValues: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        additionalEmail: PropTypes.string,
        contact: PropTypes.string,
        localAuthority: PropTypes.string,
        postcode: PropTypes.string,
        query: PropTypes.string,
        employerName: PropTypes.string,
        ilsId: PropTypes.string,
    }),
    title: PropTypes.string,
    reviewContent: PropTypes.object,
}

function ReviewPersonalDetails(props) {
    const { formValues, title, reviewContent } = props
    const {
        firstName,
        lastName,
        email,
        additionalEmail,
        contact,
        localAuthority,
        postcode,
        query,
        employerName,
        ilsId,
    } = formValues

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Stack spacing={3}>
                        <Typography
                            data-test-id='firstName-lastName'
                            startDecorator={<PortraitIcon className={'gray-bg'} />}
                        >
                            Name <br />
                            {firstName} {lastName}
                        </Typography>
                        <Typography
                            data-test-id='email'
                            startDecorator={<AlternateEmailIcon className={'gray-bg'} />}
                        >
                            Email <br /> {email}
                        </Typography>
                        {additionalEmail &&
                        <Typography startDecorator={<AlternateEmailIcon className={'gray-bg'}/>}>
                            Additional email <br/> {additionalEmail}
                        </Typography>
                        }
                        <Typography
                            data-test-id='contact'
                            startDecorator={<PhoneIcon className={'gray-bg'} />}
                        >
                            Contact <br /> {contact}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} data={localAuthorities}>
                    <h4 className={'empl-copy'}>About Employer</h4>
                    <Stack spacing={3}>
                        <Typography
                            data-test-id='employerName'
                            sx={{ pt: 2 }}
                            startDecorator={<BadgeIcon className={'gray-bg'} />}
                        >
                            Name: {employerName}
                            <br />
                            ID: {ilsId}
                        </Typography>
                        <Typography
                            data-test-id='postcode'
                            startDecorator={<FmdGoodIcon className={'gray-bg'} />}
                        >
                            Postcode: {postcode} <br />
                            Local Authority:{' '}
                            {localAuthority
                                .replace(/_/g, ' ')
                                .replace(/(?: |\b)(\w)/g, function (localAuthority) {
                                    return localAuthority.toUpperCase()
                                })}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />

            <Stack sx={{ mb: 2 }}>
                {title && <h4>{title}</h4>}
                <Typography data-test-id='query' sx={{ whiteSpace: 'pre-line' }}>
                    {query}
                </Typography>
                {reviewContent && <>{reviewContent}</>}
            </Stack>
        </>
    )
}

export default ReviewPersonalDetails
