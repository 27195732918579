import { useState } from 'react'
import JSZip from 'jszip'

function usePostForm(path) {
    const [state, setState] = useState(null)
    const [error, setError] = useState(null)

    async function postForm(values) {
        const FormData = require('form-data');
        const form = new FormData()
        const wrapper = ''

        FormData.prototype.appendRecursive = function(values, wrapper ) {
            for(const name in values) {
                if(typeof values[name] === 'object' || values[name].constructor === Array) {
                    this.appendRecursive(values[name], wrapper + '[' + name + ']');
                } else {
                    this.append( name + wrapper , values[name]);
                }
            }
        }

        form.appendRecursive(values, wrapper)

        if (Array.isArray(values.files) && values.files.length > 1) {
            const zip = new JSZip();

            await Promise.all(values.files.map(async (file) => {
                const fileContent = await file.arrayBuffer();
                zip.file(file.name, fileContent);
            }));

            const zipFile = await zip.generateAsync({ type: 'blob' });
            form.append('zipFile', zipFile, 'files.zip');

        } else if (Array.isArray(values.files) && values.files.length === 1) {
            form.append('file', values.files[0]);
        }

        try {
            const res = await fetch(path, {
                method: 'POST',
                body: form,
            })

            if (res.status >= 200 && res.status <= 299 ) {
                setState(true)
            } else {
                setState(false)
            }
        } catch (e) {
            setState(false)
            setError(e)
        }
    }

    return { state, error, postForm }
}

export default usePostForm
