import React from 'react'
import { Outlet } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Footer from './components/Footer'
import Cookies from './Cookies'

const theme = createTheme({
    typography: {
        fontFamily: ['Arial', 'Sans Serif'].join(','),
    },
})

const Layout = () => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <div>
                    <div data-testid='navBar' className={'nav-bar'}></div>
                </div>

                <div className='App'>
                    <Outlet />
                </div>
                <Cookies />
                <Footer />
            </ThemeProvider>
        </>
    )
}

export default Layout
