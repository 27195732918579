import React from 'react'
import { useFormikContext } from 'formik'
import { Typography } from '@mui/joy'
import MenuIcon from '@mui/icons-material/Menu'
import QueryAttachReview from '../QueryAttach/QueryAttachReview'

export default function PayrollReview() {
    const { values: formValues } = useFormikContext()
    const { payrollType } = formValues
    return (
        <>
            <h4>Payroll query type</h4>
            {payrollType && (
                <Typography startDecorator={<MenuIcon className={'gray-bg'} />}>
                    <span style={{ paddingRight: 2 }}>{payrollType}</span>
                </Typography>
            )}
            <QueryAttachReview />
        </>
    )
}
