import React from 'react'
import { Field } from 'formik'
import { RadioGroup } from '@mui/joy'
import { Apartment } from '@mui/icons-material'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import RadioField from './RadioField'

export default function RoleField(values) {
    const roles = [
        {
            id: 'An Employer',
            icon: <SupervisorAccountIcon />,
        },
        {
            id: 'An Employer Representative',
            icon: <SupportAgentIcon />,
        },
        {
            id: 'A Local Authority',
            icon: <Apartment />,
        },
    ]

    return (
        <RadioGroup id='roles' value={values.roles}>
            {roles.map((role, index) => (
                <Field
                    component={RadioField}
                    name='roles'
                    id={role.id}
                    label={role.id}
                    icon={role.icon}
                    key={index}
                    required={true}
                />
            ))}
        </RadioGroup>
    )
}
