import React from 'react'
import {Field, FieldArray} from 'formik';
import Grid from '@mui/material/Grid'
import {Button, Card, Divider, Sheet, Typography} from '@mui/joy';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import ClearIcon from '@mui/icons-material/Clear'
import PaHours from './Set/PaHours'
import PaExpenses from './Set/PaExpenses'
import PaFooter from './Set/PaFooter'
import PaDetails from './Set/PaDetails'
import SectionTitle from '../../FormFields/SectionTitle'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import PropTypes from 'prop-types'
import PaOtherRates from "./Set/PaOtherRates";
import PaPayment from "./Set/PaPayment";
import UploadField from "../../FormFields/UploadField";

TimesheetPage.propTypes = {
    values: PropTypes.shape({
        timesheet: PropTypes.array,
        files: PropTypes.string,
        units: PropTypes.string,
        rate: PropTypes.string,
    }),
    setFieldValue: PropTypes.func
}

export default function TimesheetPage(props) {
    const {values, setFieldValue} = props
    return (
        <div>
            <Sheet variant='soft' sx={{ p: 2, borderRadius: '20px', mt: 2 }}>
            <h2 style={{ margin:0}}>Add Timesheet</h2>

            <Typography my={2} sx={{fontWeight: 'bold'}}>
                Deadline for guaranteed payment the same week:{' '}
                <Typography variant="outlined" color="success">Monday at noon</Typography><br />
            </Typography>
            <Typography level={'body1'} mb={3}>
                Whilst we will endeavour to process late submissions as
                soon as possible, we cannot guarantee we can make the payment in the same week.
            </Typography>
            <FieldArray
                values={props.values}
                name='timesheet'
                render={(arrayHelpers) => (
                    <div>
                        {props.values.timesheet && props.values.timesheet.length >0? (
                            props.values.timesheet.map((sheet,index) => (
                                <div key={index}>
                                    <div className={'log-card'}>
                                        <Grid container mb={3}>
                                            <Grid item xs={1} className={'gray-bg pa-no'}>
                                                {`${index +1}`}
                                            </Grid>
                                            <Grid item xs={10}>
                                                <h2 style={{ margin:0,paddingTop:5}}>
                                                    Personal Assistant Details
                                                </h2>
                                            </Grid>
                                        </Grid>

                                        <PaDetails index={index} />
                                        <PaHours index={index} />
                                        <PaOtherRates index={index}
                                                      values={values}
                                                      setFieldValue={setFieldValue}
                                        />
                                        <PaExpenses index={index} />
                                        <PaPayment index={index} />
                                        <PaFooter index={index} />
                                        <Grid container spacing={3} mt={2}>
                                            <Grid item xs={12} sm={6}>
                                                <Button
                                                    variant='outlined'
                                                    startDecorator={<ClearIcon />}
                                                    onClick={() => arrayHelpers.remove(index)}
                                                    sx={{ py:2, border: '1px solid gray', color: 'black'}}
                                                    disabled={props.values.timesheet.length === 1}
                                                >
                                                    Delete Timesheet
                                                </Button>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Button
                                                    color={'danger'}
                                                    sx={{ py:2}}
                                                    startDecorator={<PersonAddAltIcon />}
                                                    onClick={() => arrayHelpers.push('')}
                                                >
                                                    Add More Timesheet
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <Divider sx={{ my:2}} />
                                </div>
                            ))
                        ) : null }
                    </div>
                )}
            />
            </Sheet>
            <SectionTitle
                title={'Related Attachments (Optional)'}
                body={
                    'Attach anything here that relates to this timesheet submission. This may include sickness and maternity/paternity documentation; once attached you do not need to raise a separate ticket.'
                }
            />
            <Sheet sx={{ p:2,borderRadius:3,my:3,backgroundColor: 'lightgray' }}>
                <Typography startDecorator={<PriorityHighIcon className={'gray-bg'} />}>
                    Please do not attach any old-style timesheet in this section as these will not
                    be processed. For timesheet hours, please enter the relevant details above.
                </Typography>
            </Sheet>
            <Typography my={2}>
                <Typography variant="outlined" color="danger" sx={{fontWeight: 'bold'}}>
                    East Sussex Region only</Typography> : Please attach any expense documentation.
            </Typography>

            <UploadField setFieldValue={setFieldValue} values={values} />

            <Divider sx={{ my:5}} />
            <Card className={'radio-card'}>
                <label>
                    <Grid container style={{cursor: "pointer", padding: '10px 20px 10px 10px '}}>
                        <Field
                            type='checkbox'
                            name={`declaration`}
                            className={'checked'}
                            style={{top: 20, left: 20}}
                            required={true} />
                        <Grid item pl={5}>
                            <span>I confirm that the following is a correct record of the total hours worked by the employee(s) for the period detailed. I authorise PeoplePlus to calculate payroll and make the relevant payments to my employee(s) and to HM Revenue and Customs as required.</span>
                        </Grid>
                    </Grid>
                </label>
            </Card>
        </div>
    )
}

