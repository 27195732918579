import personalDetailsFormModel from "../PersonalDetails/FormModel/personalDetailsFormModel";
import queriesFormModel from "../GeneralQuery/FormModel/queriesFormModel";


const {
    personalDetailsFormField: {
        firstName,
        lastName,
        email,
        additionalEmail,
        contact,
        localAuthority,
        postcode,
        employerName,
        ilsId,
    },
} = personalDetailsFormModel

const {
    queriesFormField: { form, employerEmail, dob },
} = queriesFormModel

const myEpayInitialValues = {
    [form.name]: 'myEpay',
    [firstName.name]: '',
    [lastName.name]: '',
    [email.name]: '',
    [additionalEmail.name]: '',
    [contact.name]: '',
    [employerName.name]: '',
    [ilsId.name]: '',
    [localAuthority.name]: '',
    [postcode.name]: '',
    [employerEmail.name]: '',
    [dob.name]: '',
}

export default myEpayInitialValues
