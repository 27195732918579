import * as React from 'react'
import ListItem from '@mui/material/ListItem'
import PropTypes from 'prop-types'
import { Divider, IconButton, Tooltip, Grid } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import Menu from '@mui/joy/Menu'
import MenuItem from '@mui/joy/MenuItem'
import Apps from '@mui/icons-material/Apps'
import MenuButton from '../Buttons/MenuButton'
import PAQuery from '../Buttons/PAQuery'
import { useNavigate, useLocation } from 'react-router-dom';
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {Sheet, Typography} from "@mui/joy";


export default function InputFormTitle({ title, subtitle, tooltip }) {
    InputFormTitle.propTypes = {
        title: PropTypes.string.isRequired,
        tooltip: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
    }

    const [menuIndex, setMenuIndex] = React.useState(null)
    const navigate = useNavigate()
    const navigateToHome = () => {
        navigate('/')
    }
    const navigateToHmrc = () => {
        navigate('/hmrc')
    }
    const navigateToPension = () => {
        navigate('/pensions')
    }
    const navigateToDocRequest = () => {
        navigate('/document-request')
    }
    const navigateToInvoice = () => {
        navigate('/invoice')
    }
    const navigateToAdvisor = () => {
        navigate('/local-advisor')
    }
    const navigateToPayroll = () => {
        navigate('/payroll')
    }
    const navigateToComplaints = () => {
        navigate('/complaints')
    }
    const navigateToMyePay = () => {
        navigate('/myepay')
    }
    const menus = [
        {
            title: 'Home',
            path: navigateToHome,
        },
        {
            title: 'Submit Hours & Timesheet',
            path: '/timesheet',
        },
        {
            title: 'HMRC Query',
            path: navigateToHmrc,
        },
        {
            title: 'Pension Query',
            path: navigateToPension,
        },
        {
            title: 'Document Request',
            path: navigateToDocRequest,
        },
        {
            title: 'Invoicing & Managed Account',
            path: navigateToInvoice,
        },
        {
            title: 'Payroll Query',
            path: navigateToPayroll,
        },
        {
            title: 'MyePay Request',
            path: navigateToMyePay,
        },
        {
            title: 'Local Advisor Query',
            path: navigateToAdvisor,
        },
        {
            title: 'Compliments and Complaints',
            path: navigateToComplaints,
        },
    ]

    const createHandleLeaveMenu = (index) => (getIsOnButton) => {
        setTimeout(() => {
            const isOnButton = getIsOnButton()
            if (!isOnButton) {
                setMenuIndex((latestIndex) => {
                    if (index === latestIndex) {
                        return null
                    }
                    return latestIndex
                })
            }
        }, 200)
    }

    const tsMenu = {
        textDecoration: 'none',
        color: 'unset'
    };

    const location = useLocation();

    return (
        <>
            <Grid container>
                <Grid item>
                    <MenuButton
                        style={{ marginTop: 7, borderRadius: 10, backgroundColor: '#e5e5e5' }}
                        label='Settings'
                        open={menuIndex === 1}
                        onOpen={() => setMenuIndex(1)}
                        onLeaveMenu={createHandleLeaveMenu(1)}
                        menu={
                            <Menu onClose={() => setMenuIndex(null)} className={'pp-menu'}>
                                {menus.map((menu) => (
                                    <MenuItem
                                        className={'pp-menu-children'}
                                        key={menu.title}
                                        onClick={menu.path}
                                    >
                                        <a href={menu.path} style={tsMenu}>
                                            <h4>{menu.title}</h4>
                                        </a>
                                    </MenuItem>
                                ))}
                            </Menu>
                        }
                    >
                        <Apps style={{ color: 'indianred', fontSize: 'larger' }} />
                    </MenuButton>
                </Grid>
                <Grid sx={{ ml: 2 }}>
                    <ListItem style={{ margin: 0, padding: 0 }}>
                        <h3>{title}</h3>
                        <Grid item>
                            <Tooltip title={tooltip}>
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </ListItem>
                </Grid>
            </Grid>

            {
                location.pathname === '/myepay' ? (
                    <Sheet sx={{ p: 2, borderRadius: 3, my: 3, backgroundColor: 'lightgray' }}>
                        <Typography startDecorator={<PersonOutlineIcon className={'gray-bg'} />}>
                        Please complete this form in order to register for access to MyePay, which will provide you with access to view Payslips, P45, P60 and other documentation.
                        </Typography>
                    </Sheet>
                ) : (
                    <PAQuery subtitle={subtitle} />
                    )
            }

            <Divider sx={{ mt: 2 }} />
        </>
    )
}
