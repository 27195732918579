import personalDetailsFormModel from '../../PersonalDetails/FormModel/personalDetailsFormModel'
import timesheetFormModel from './timesheetFormModel'

const {
    personalDetailsFormField: {
        firstName,
        lastName,
        email,
        additionalEmail,
        contact,
        localAuthority,
        postcode,
        employerName,
        ilsId,
    },
} = personalDetailsFormModel

const {
    timesheetFormField: { form, files },
} = timesheetFormModel

const timesheetInitialValues = {
    [form.name]: '',
    [firstName.name]: '',
    [lastName.name]: '',
    [email.name]: '',
    [additionalEmail.name]: '',
    [contact.name]: '',
    [employerName.name]: '',
    [ilsId.name]: '',
    [localAuthority.name]: '',
    [postcode.name]: '',
    timesheet: [{
        pa_first_name: '',
    }],
    [files.name]:[],
}

export default timesheetInitialValues
