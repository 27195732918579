import * as React from 'react';
import SectionTitle from "../../../FormFields/SectionTitle";
import {Card, Divider, Grid, Sheet, Typography} from "@mui/joy";
import PropTypes from "prop-types";
import {getIn} from 'formik';
import {InputField} from "../../../FormFields";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import DragHandleRoundedIcon from '@mui/icons-material/DragHandleRounded';
import {InputAdornment, OutlinedInput} from "@mui/material";
import {NumericFormat} from "react-number-format";

PaOtherRates.propTypes = {
    index: PropTypes.number,
    setFieldValue: PropTypes.func,
    values: PropTypes.object,
    name: PropTypes.string,
    label: PropTypes.string,
    calculate: PropTypes.string,
}

export default function PaOtherRates(props) {
    const {setFieldValue, values} = props

    const headers = [
        {
            title: 'Rate type',
            size:3,
        },{
            title: 'No. of Units',
            size:3,
        },{
            title: 'Unit Rate (£)',
            size:3,
        },{
            title: 'Total (£)',
            size:2,
        },
    ]

    const rows = [
        {
            title: 'Overnight',
            unit: `overnight_unit`,
            rate: `overnight_rate`,
            total: `overnight_total`,
        },{
            title: 'Live in',
            unit: `livein_unit`,
            rate: `livein_rate`,
            total: `livein_total`,
        },{
            title: 'Respite Care',
            unit: `respite_unit`,
            rate: `respite_rate`,
            total: `respite_total`,
        },{
            title: 'Other',
            unit: `other_unit`,
            rate: `other_rate`,
            total: `other_total`,
            note: `timesheet[${props.index}].other_rate_note`,
        },
    ];

    return (
        <div>
            <SectionTitle title={'Other Additional Rates'} applicable={true} />
            <Card className={'warning-card border'}>
                <Typography>
                    You can either detail:<br />
                    <Typography sx={{lineHeight: 3}} variant="outlined" color="success">Number of hours and an hourly rate</Typography> or{' '}
                    <Typography variant="outlined" color="primary">
                        Number of shifts and Shift rate
                    </Typography>.
                </Typography>
                <Typography pt={1}>
                    <strong>Examples:</strong><br />
                    To pay your PA for {' '}
                    <Typography sx={{lineHeight: 2}} variant="outlined" color="success">
                        9 hours at £15.20 per hour rate
                    </Typography>: insert <strong>9 </strong>
                    into the No. of Units box and
                    <strong> 15.20 </strong>in the Unit rate box.
                    <br />
                    To pay your PA for a {' '}
                    <Typography sx={{lineHeight: 2}} variant="outlined" color="primary">
                        2 shifts works at a £200 a shift rate
                    </Typography>: insert <strong> 2 </strong> into the No. of Units box and <strong> 200 </strong> in the Unit rate box.
                </Typography>
            </Card>
            <Typography my={2}>
                <Typography variant="outlined" color="success">Unit</Typography>
                {' '}: The number of hours/shifts worked.
                <div style={{marginTop: 10}}>
                    <Typography variant="outlined" color="primary" >
                        Unit Rate</Typography>
                    {' '}: The rate of pay per hour/shift.
                </div>
            </Typography>
            <Card sx={{border: 'lightgray 1px solid',padding: 0}}>
                <Grid container spacing={2} p={2}>
                    {headers.map((header,index) => (
                        <Grid item xs={header.size} key={index} p={1}
                              style={{fontWeight: 'bold',color: '#b11116'}}>
                            {header.title}
                        </Grid>
                    ))}
                </Grid>

                {rows.map((row, index) => {
                    const unit = getIn(values, `timesheet[${props.index}].${row.unit}`);
                    const rate = getIn(values, `timesheet[${props.index}].${row.rate}`);
                    let total = (unit * rate).toFixed(2);

                    React.useEffect(() => {
                        setFieldValue(`timesheet[${props.index}].${row.total}`, total);
                    }, [unit, rate]);

                    if (unit && rate) {
                        total = (unit * rate).toFixed(2);
                    } else {
                        total = null;
                    }


                    return (
                        <div key={index}>
                            <Divider sx={{ mx: 1}} />
                            <Grid container m={1} py={2}>
                                <Grid item xs={12} sm={3} pl={1}>
                                    <h4>{row.title}</h4>
                                </Grid>

                                <Grid xs={4} sm={2}>
                                    <NumericFormat
                                        label={'Unit'}
                                        placeholder={'Unit'}
                                        name={row.unit}
                                        value={unit}
                                        className={'extra-hr'}
                                        required={!!rate}
                                        decimalScale={2}
                                        allowNegative={false}
                                        onValueChange={(values) => {
                                            setFieldValue(`timesheet[${props.index}].${row.unit}`, parseFloat(values.value))
                                        }}
                                    />
                                </Grid>
                                <Grid item><ClearRoundedIcon sx={{p: '15px 10px'}} /></Grid>

                                <Grid item xs={4} sm={2}>
                                    <NumericFormat
                                        label={'Rate'}
                                        placeholder={'Rate'}
                                        name={row.rate}
                                        value={rate}
                                        className={'extra-hr'}
                                        required={!!unit}
                                        fixedDecimalScale
                                        decimalScale={2}
                                        allowNegative={false}
                                        prefix={'£'}
                                        onValueChange={(values) => {
                                            setFieldValue(`timesheet[${props.index}].${row.rate}`, parseFloat(values.value))
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <DragHandleRoundedIcon sx={{p: '15px 10px 15px 25px'}} />
                                </Grid>

                                <Grid xs={6} sm={2}>
                                    <OutlinedInput
                                        placeholder={'0.00'}
                                        style={{width: '120%', backgroundColor: '#f5f5f5'}}
                                        startAdornment={<InputAdornment position="start">&pound;</InputAdornment>}
                                        name={row.total} value={total} readOnly />
                                </Grid>
                            </Grid>
                            {row.note && row.rate && <>
                                <Sheet sx={{px: 2, mb:2}}>
                                    <InputField label={'Other: Please Specify'}
                                                name={row.note}
                                                multiline={true}
                                                minRows={1}
                                                required={!!rate}
                                                fullWidth
                                                helperText={'If classed as ‘other’, please specify the information, ' +
                                                'e.g ‘Supervision Hours’.'} />
                                </Sheet>
                            </> }
                        </div>
                    );
                })}
            </Card>
        </div>
    );
}
