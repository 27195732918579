import React from 'react'
import { Card, Sheet, Stack, Typography } from '@mui/joy'
import PropTypes from 'prop-types'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { useFormikContext } from 'formik'

PayslipReview.propTypes = {
    title: PropTypes.string,
    values: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        contact: PropTypes.string,
        employerName: PropTypes.string,
        ilsId: PropTypes.string,
        postcode: PropTypes.string,
        localAuthority: PropTypes.string,
        docRequest: PropTypes.array,
        files: PropTypes.array,
    }),
}

export default function PayslipReview(props) {
    const review = props.values
    const { values: formValues } = useFormikContext()
    const { payslipComment } = formValues
    const convert = function (match, y, m, d) {
        return d + '/' + m + '/' + y
    }

    return (
        <>
            <Sheet variant='soft' sx={{ p: 2, borderRadius: '20px', mt: 2 }}>
                <h4 style={{ marginTop: 5 }}>List of PAs requiring {props.title} copies</h4>
                {review.docRequest &&
                    review.docRequest.map((item, i) => {
                        return (
                            <Card key={i} variant={'outlined'} sx={{ my: 2 }}>
                                <h3 style={{ marginTop: 1 }}>
                                    {item.pa_first_name} {item.pa_last_name}
                                </h3>
                                <div className={'bold-border'} />
                                {item.dateFrom && (
                                    <Stack spacing={3}>
                                        <Typography
                                            startDecorator={<DateRangeIcon className={'gray-bg'} />}
                                        >
                                            <Typography sx={{ mr: 1 }}>Date:</Typography>
                                            <Typography variant='soft' component='strong'>
                                                {item.dateFrom.replace(
                                                    /(\d{4})-(\d{1,2})-(\d{1,2})/,
                                                    convert,
                                                )}{' '}
                                                to{' '}
                                                {item.dateTo.replace(
                                                    /(\d{4})-(\d{1,2})-(\d{1,2})/,
                                                    convert,
                                                )}
                                            </Typography>
                                        </Typography>
                                    </Stack>
                                )}
                                {item.endDate && (
                                    <Stack spacing={3}>
                                        <div>
                                            Employment End Date:{' '}
                                            {item.endDate.replace(
                                                /(\d{4})-(\d{1,2})-(\d{1,2})/,
                                                convert,
                                            )}
                                        </div>
                                    </Stack>
                                )}
                            </Card>
                        )
                    })}
            </Sheet>
            {payslipComment && (
                <>
                    <h3>Additional Comments</h3>
                    <p>{payslipComment}</p>
                </>
            )}
        </>
    )
}
