import React from 'react'
import { Grid } from '@mui/material'
import { InputMessage } from '../../FormFields'
import { RadioGroup } from '@mui/joy'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import RadioField from '../../FormFields/RadioField'
import UploadField from "../../FormFields/UploadField";

InvoicePage.propTypes = {
    queriesFormField: PropTypes.shape({
        query: PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        }),
        invoiceType: PropTypes.shape({
            name: PropTypes.string,
        }),
    }),
    values: PropTypes.shape({
        files: PropTypes.array,
    }),
    errors: PropTypes.object,
    setFieldValue: PropTypes.func,
}

export default function InvoicePage(props) {
    const {
        queriesFormField: { query },
    } = props

    const invoiceTypes = [
        'Invoice Submissions Portal – Account Queries',
        'Invoice & Payment queries',
        'Personal Contributions',
        'Direct Debits',
        'Service Fees',
        'Statement Request',
        'Other',
    ]

    const { values, setFieldValue } = props

    return (
        <React.Fragment>
            <h2>Invoice and Managed Accounts Queries</h2>
            <h3>Select the query related to this submission:</h3>
            <RadioGroup id='invoiceType' value={invoiceTypes}>
                {invoiceTypes.map((invoiceType, index) => (
                    <Field
                        component={RadioField}
                        name='invoiceType'
                        id={invoiceType}
                        label={invoiceType}
                        key={index}
                        required
                    />
                ))}
            </RadioGroup>

            <h2 style={{paddingTop:15}}>Your Queries</h2>
            <Grid item xs={12}>
                <InputMessage name={query.name} label={query.label} required fullWidth />
            </Grid>

            <h2>
                Upload supporting documents
                <span style={{ fontSize: 'medium' }}> (Optional)</span>
            </h2>
            <UploadField setFieldValue={setFieldValue} values={values} />
        </React.Fragment>
    )
}
