import React from 'react'
import { useLocation } from 'react-router-dom';
import { InputMessage} from '../../FormFields';
import PropTypes from 'prop-types'
import UploadField from "../../FormFields/UploadField";
import {Sheet, Typography} from "@mui/joy";

export default function QueryAttachPage(props) {
    const {values, setFieldValue} = props
    const {
        queriesFormField: { query },
    } = props

    const location = useLocation();

    return (
        <React.Fragment>
            <h2>{props.title}</h2>
            <h4>{props.subtitle}</h4>
            <InputMessage name={query.name} label={query.label} required fullWidth />
            <h2>
                Upload supporting documents
                <span style={{ fontSize: 'medium' }}>{' '}(Optional)</span>
            </h2>

            {
                location.pathname === '/hmrc' && (
                    <Sheet color="danger" variant="soft" sx={{ p: 2, borderRadius: 3, my: 3}}>
                        <Typography>
                            If you are attaching HMRC correspondence,{' '}
                            <Typography style={{ fontWeight: 'bold', textDecorationLine: 'underline'}}>please ensure ALL of the pages are uploaded. </Typography>
                            Failure to do so will result in a delay to our processing as we may need to contact you for further information.
                        </Typography>
                    </Sheet>
                )
            }

            <UploadField setFieldValue={setFieldValue} values={values} />
        </React.Fragment>
    )
}

QueryAttachPage.propTypes = {
    queriesFormField: PropTypes.shape({
        query: PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        })
    }),
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    setFieldValue: PropTypes.func,
    values: PropTypes.shape({
        files: PropTypes.string
    }),
}
