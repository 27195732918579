import { createTheme, ThemeProvider } from '@mui/material/styles'
import React from 'react'
import { Card } from '@mui/material'
import '../../index.css'
import DynamicMenu from '../Home/DynamicMenu'
import { Divider, Grid} from '@mui/joy';
import { useNavigate } from 'react-router-dom'

const theme = createTheme({
    typography: {
        fontFamily: ['Arial', 'Sans Serif'].join(','),
    },
})

function Home() {
    const navigate = useNavigate()
    const departments = [
        {
            title: 'Submit Hours & Timesheet',
            icon: '/icons/timesheet.svg',
            onClick: () => navigate('/timesheet'),
        },
        {
            title: 'Local Advisor Query',
            icon: '/icons/advisor.svg',
            onClick: () => navigate('/local-advisor'),
        },
        {
            title: 'Document Request',
            icon: '/icons/payslip.svg',
            onClick: () => navigate('/document-request'),
            subtitle: 'Payslip, P45 and P60',
        },
        {
            title: 'MyePay Registration',
            icon: '/icons/myepay.svg',
            onClick: () => navigate('/myepay'),
        },
        {
            title: 'HMRC Query',
            icon: '/icons/hmrc.svg',
            onClick: () => navigate('/hmrc'),
        },
        {
            title: 'Pensions Query',
            icon: '/icons/pension.svg',
            onClick: () => navigate('/pensions'),
        },
        {
            title: 'Invoicing & Managed Accounts Query',
            icon: '/icons/invoice.svg',
            onClick: () => navigate('/invoice'),
        },
        {
            title: 'Payroll Query',
            icon: '/icons/payroll.svg',
            onClick: () => navigate('/payroll'),
            subtitle: 'Sick Pay, Maternity/Paternity Pay, and others',
        },
        {
            title: 'Compliments and Complaints',
            icon: '/icons/complaints.svg',
            onClick: () => navigate('/complaints'),
        },
    ]

    return (
        <ThemeProvider theme={theme}>
            <Card className={'pp-card'}>
                <Grid container>
                    <Grid item xs={11} sm={9}>
                        <img src='/ils-pp-logo.svg' alt='PeoplePlus ILS Logo' width={'100%'} height={'100%'} />
                    </Grid>
                </Grid>

                <Divider sx={{ my: 3 }} />

                <p style={{ textAlign: 'center', padding: '0 20px' }}>
                    The Independent Living Support Service is designed to help people remain
                    independent in their own homes by assisting with day-to-day living tasks. <br />{' '}
                    <br />
                    We tailor the service to your preferences and needs including coordinating the
                    paperwork between local authorities, agencies, employer and personal assistants.
                </p>

                <Divider sx={{ my: 3 }} />

                <Grid container spacing={2}
                      role="navigation"
                      aria-label="Main Menu"
                      alignItems="stretch"
                >
                    {departments.map((props, index) => (
                        <Grid item xs={6} sm={4} key={index} style={{ height: '280px' }}>
                            <DynamicMenu {...props} />
                        </Grid>
                    ))}
                </Grid>
            </Card>
        </ThemeProvider>
    )
}
export default Home
