import { useField } from 'formik'
import { TextField } from '@mui/material'
import React from 'react'

export default function InputField(props) {
    const [field, meta] = useField(props)

    const handleOnChange = (event) => {
        event.target.value = event.target.value.trim();
        field.onChange(event);
    }

    return (
        <TextField
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            {...field}
            {...props}
            onBlur={handleOnChange}
        />
    )
}
