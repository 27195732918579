const attachmentPageData = [
    {
        title: 'HMRC / Tax Query',
        tooltip: 'This is a form to submit HMRC / Tax query.',
        subtitle: `HMRC / Tax query`,
        path: 'hmrc',
    },
    {
        title: 'Pension Query',
        subtitle: `pension query`,
        tooltip: 'This is a form to submit pension query.',
        path: 'pensions',
    },
    {
        title: 'Sick Pay Query',
        subtitle: `raise a sick pay query`,
        tooltip:
            'This is a form to submit sick pay enquires and attach any relevant documentation.',
        path: 'payroll/sickpay',
    },
    {
        title: 'Maternity/Paternity Pay Query',
        subtitle: `raise a maternity/paternity pay query`,
        tooltip: 'This is a form to submit maternity/paternity pay enquiry and submit your MAT B1 form.',
        path: 'payroll/maternity-paternity',
    },
    {
        title: 'Local Advisor Query',
        subtitle: `local advisor contact form`,
        tooltip: 'This is a form to submit local advisor query.',
        path: 'local-advisor',
    },
    {
        title: 'Compliments and Complaints',
        subtitle: `compliment or a complaint`,
        tooltip: 'This is a form to submit compliment or a complaint.',
        path: 'complaints',
        complaints: true
    },
]

export default attachmentPageData
