import React from 'react'
import { at } from 'lodash'
import { useField } from 'formik'
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'

InputMessage.propTypes = {
    errorText: PropTypes.string,
}

export default function InputMessage(props) {
    const { errorText, ...rest } = props
    const [field, meta] = useField(props)

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error')
        if (touched && error) {
            return error
        }
    }

    return (
        <TextField
            sx={{ mb: 3 }}
            type='text'
            error={meta.touched && meta.error && true}
            helperText={_renderHelperText()}
            {...field}
            {...rest}
            multiline={true}
            minRows={3}
        />
    )
}
