import React from 'react'
import { Grid, Typography } from '@mui/joy'
import { InputField } from '../../FormFields'
import DatePicker from '../../FormFields/DatePicker'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import {Divider} from "@mui/material";

export default function EmployerDetails() {
    return (
        <div>
            <p>
                If you have e-Payslips to be uploaded to the MyePay portal, please use the {' '}
                <a href="/document-request/payslip">
                    Payslips Request here
                </a>.
                You can request payslips for the period you require.
            </p>

            <p>
                For any other queries regarding MyePay such as Account or Log in issues,
                please call the ILS Central line at
                <a href="tel:03301232815" style={{padding:6}}>0330 123 2815</a>
                and we will be happy to help.
            </p>

            <Divider sx={{ mt: 3 }} />

            <h2>Your Details</h2>
            <p>
                This info will help us create your account and register your access to the MyePay
                system. Once you&apos;ve provided these details, we&apos;ll send you a &apos;Secret
                Key&apos; to your email. You&apos;ll need this key to complete your registration.
            </p>

            <Typography
                style={{ marginInlineEnd: 0 }}
                sx={{ my: 4 }}
                startDecorator={
                    <PersonOutlineIcon className={'gray-bg'} style={{ marginRight: 0 }} />
                }
            >
                <InputField
                    name='employerEmail'
                    label='Your Email'
                    fullWidth
                    required
                    type='email'
                />
            </Typography>

            <Grid container>
                <Grid xs={12} sm={6}>
                    <DatePicker name='dob' label={'Your Date of Birth'} required={true} />
                </Grid>
            </Grid>
        </div>
    )
}
