import * as Yup from 'yup'
import personalDetailsFormModel from "../PersonalDetails/FormModel/personalDetailsFormModel";

const {
    personalDetailsFormField: {
        firstName,
        lastName,
        email,
        contact,
        localAuthority,
        postcode,
        employerName,
        ilsId,
    },
} = personalDetailsFormModel

const invoiceSchema = [
    Yup.object().shape({
        [firstName.name]: Yup.string().required(firstName.requiredErrorMsg),

        [lastName.name]: Yup.string().required(lastName.requiredErrorMsg),

        [localAuthority.name]: Yup.string().nullable().required(localAuthority.requiredErrorMsg),

        [postcode.name]: Yup.string()
            .matches(/^[a-zA-Z]{1,2}[0-9][0-9A-Za-z]{0,1} {0,1}[0-9][A-Za-z]{2}$/, {
                message: postcode.invalidErrorMsg,
            })
            .required(postcode.requiredErrorMsg),

        [contact.name]: Yup.string().required(contact.requiredErrorMsg),

        [email.name]: Yup.string()
            .email(email.invalidEmail)
            .required(email.requiredErrorMsg),

        [employerName.name]: Yup.string().required(employerName.requiredErrorMsg),

        [ilsId.name]: Yup.string()
            .length(9, ilsId.invalidErrorMsg),
    }),
]

export default invoiceSchema
