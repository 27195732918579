import * as React from 'react'
import { Button, Divider, Grid, Link } from '@mui/joy'
import Stack from '@mui/material/Stack'
import CallIcon from '@mui/icons-material/Call'

export default function Footer() {
    const peoplePlus = 'https://www.peopleplus.co.uk/'
    const contact = {
        title: '0800 345666',
        path: 'tel:0800345666',
    }
    const policies = [
        {
            title: 'Privacy',
            path: '/policies/privacy-policy',
        },
        {
            title: 'Modern Slavery',
            path: '/policies/modern-slavery',
        },
        {
            title: 'Cookies',
            path: '/policies/cookies-policy',
        },
        {
            title: 'Terms and conditions',
            path: '/policies/terms-and-conditions',
        },
        {
            title: 'Website Acceptable Use',
            path: '/policies/acceptable-use-policy',
        },
        {
            title: 'Complaints and Compliments',
            path: '/policies/complaints-and-compliments-procedure-d',
        },
    ]

    return (
        <>
            <Grid container className={'footer'}>
                <Grid item xs={12} sm={5} sx={{ pr: 4 }}>
                    <Stack spacing={2}>
                        <img src='/peopleplus-logo.svg' width={'120px'} height={'100%'} alt={'PeoplePlus logo'} />
                        <div style={{color: 'black'}}>
                            We are a leading skills and training business helping people to
                            transform their lives and businesses through work, training, education
                            and financial advice. We help people source and retain sustainable
                            employment, whilst working with employers to develop work forces of the
                            future.
                        </div>
                        <Link href={peoplePlus} target="_blank">
                            <Button
                                variant='plain'
                                size='sm'
                                style={{ color: '#8F1500', border: '1px solid lightgray' }}
                            >
                                <h4>Learn more about PeoplePlus</h4>
                            </Button>
                        </Link>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <h4>Contact Us</h4>
                    <Stack spacing={2}>
                        <Link sx={{color: '#8F1500'}} href={contact.path} startDecorator={<CallIcon />}>
                            {contact.title}
                        </Link>
                        <img src='/ils-pp-logo.svg' width={'250px'} height={15} alt={'PeoplePlus logo'} />
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div>
                        PeoplePlus Group Ltd part of Staffline Group plc. 19-20 The Triangle, NG2
                        Business Park, Nottingham, NG2 1AE.
                    </div>
                    <p>
                        &#169; 2022 PeoplePlus Group Ltd.
                        <br /> Company number: 5722765
                    </p>
                </Grid>

                <div style={{ fontSize: 'small', width: '100%' }}>
                    <Divider sx={{ my: 2 }} />
                    <ul style={{ padding: 0, margin: 0 }}>
                        <li className={'pr-content'}>
                            {policies.map((policy) => (
                                <Link
                                    className={'pr-text'}
                                    key={policy.title}
                                    href={policy.path}
                                    target={'_blank'}
                                    sx={{color:'#7A0000'}}
                                >
                                    {policy.title}
                                </Link>
                            ))}
                        </li>
                    </ul>
                </div>
            </Grid>
        </>
    )
}
