import React, {useRef} from 'react'
import {Field} from "formik";
import PropTypes from "prop-types";
import UploadIcon from '@mui/icons-material/Upload';
import {Box, Card, Grid, IconButton, Typography} from "@mui/joy";
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ClearIcon from '@mui/icons-material/Clear';

UploadField.propTypes = {
    values: PropTypes.shape({
        timesheet: PropTypes.array,
        files: PropTypes.array
    }),
    setFieldValue: PropTypes.func
}

export default function UploadField(props) {
    const fileInputRef = useRef(null);
    const {setFieldValue, values} = props
    return (
        <>
            <label htmlFor="files">
                <Typography
                    startDecorator={<UploadIcon fontSize={'large'}/>}
                    className={'upload-btn'}
                >
                    <span>Upload File(s)</span>
                </Typography>
            </label>

            <Field
                id={"files"}
                name="files"
                type="file"
                className={'hidden'}
                multiple
                value={undefined}
                innerRef={fileInputRef}
                onChange={(event) => {
                    const files = Array.from(event.target.files);
                    setFieldValue("files", [...values.files, ...files]);
                    fileInputRef.current && (fileInputRef.current.value = '');
                }}
            />
            <Grid container>
                <Grid item xs={12}>
                    <div>
                        {Array.isArray(values.files) && values.files.map((file, index) => (
                            <div key={file.name}>
                                <Card row="true" variant='outlined' sx={{ mb:2}}>

                                    <Box sx={{ display: 'flex',gap:1.5,mt: 'auto' }}>
                                        <AttachFileIcon
                                            className={'gray-bg'}
                                            sx={{ width:30,fontSize:30,ml:2}}
                                        />
                                        <div>
                                            <Typography fontWeight='md' color='danger' mb={0.5}>
                                                {file.name}</Typography>
                                            <Typography fontWeight="lg" level="body2">
                                                {file.size} bytes
                                            </Typography>
                                        </div>
                                        <IconButton variant="outlined" color={'danger'}
                                                    onClick={() => {
                                                        const newFiles = [...values.files];
                                                        newFiles.splice(index, 1);
                                                        setFieldValue("files", newFiles);
                                                    }}
                                                    sx={{ width:50}}
                                                    className={'cell'}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Box>
                                </Card>
                            </div>
                        ))}
                    </div>
                </Grid>
            </Grid>
        </>
    )
}
