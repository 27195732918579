import { Button, Grid } from '@mui/joy'
import React from 'react'
import HomeIcon from '@mui/icons-material/Home'
import { useNavigate } from 'react-router-dom'

export default function SuccessRedirect() {
    const navigate = useNavigate()

    return (
        <Grid container>
            <Grid item xs={8}>
                <Button
                    startDecorator={<HomeIcon />}
                    size={'lg'}
                    onClick={() => navigate('/')}
                    color={'danger'}
                    sx={{ mb: 3, ml: 3, mt: 5 }}
                >
                    Home
                </Button>
            </Grid>
            <Grid item xs={12} sx={{ pb: 5, ml: 3 }}>
                <img src='/peopleplus-logo.svg' width={'250px'} height={'100%'} alt={'PeoplePlus Logo'} />
            </Grid>
        </Grid>
    )
}
